import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, withModifiers as _withModifiers, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withScopeId as _withScopeId } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BorderLine = _resolveComponent("BorderLine")!
  const _component_ResizeHandler = _resolveComponent("ResizeHandler")!
  const _component_RotateHandler = _resolveComponent("RotateHandler")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["image-element-operate", { 'cliping': _ctx.isCliping }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.borderLines, (line) => {
      return (_openBlock(), _createBlock(_component_BorderLine, {
        class: "operate-border-line",
        key: line.type,
        type: line.type,
        style: _normalizeStyle(line.style)
      }, null, 8, ["type", "style"]))
    }), 128)),
    (!_ctx.elementInfo.lock && (_ctx.isActiveGroupElement || !_ctx.isMultiSelect))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resizeHandlers, (point) => {
            return (_openBlock(), _createBlock(_component_ResizeHandler, {
              class: "operate-resize-handler",
              key: point.direction,
              type: point.direction,
              rotate: _ctx.elementInfo.rotate,
              style: _normalizeStyle(point.style),
              onMousedown: _withModifiers($event => _ctx.scaleElement($event, _ctx.elementInfo, point.direction), ["stop"])
            }, null, 8, ["type", "rotate", "style", "onMousedown"]))
          }), 128)),
          _createVNode(_component_RotateHandler, {
            class: "operate-rotate-handler",
            style: _normalizeStyle({ left: _ctx.scaleWidth / 2 + 'px' }),
            onMousedown: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.rotateElement(_ctx.elementInfo)), ["stop"]))
          }, null, 8, ["style"])
        ], 64))
      : _createCommentVNode("", true)
  ], 2))
}