
import { computed, defineComponent, ref, watch } from 'vue'
import { MutationTypes, useStore } from '@/store'
import { PPTShapeElement, ShapeGradient, ShapeText } from '@/types/slides'
import { WEB_FONTS, FONT_SIZE_OPTIONS } from '@/configs/font'
import emitter, { EmitterEvents } from '@/utils/emitter'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'

import ElementOpacity from '../common/ElementOpacity.vue'
import ElementOutline from '../common/ElementOutline.vue'
import ElementShadow from '../common/ElementShadow.vue'
import ElementFlip from '../common/ElementFlip.vue'
import ColorButton from '../common/ColorButton.vue'
import FontNameSelect from '../common/FontNameSelect.vue'
import FontSizeSelect from '../common/FontSizeSelect.vue'

const webFonts = WEB_FONTS

export default defineComponent({
    name: 'shape-style-panel',
    components: {
        ElementOpacity,
        ElementOutline,
        ElementShadow,
        ElementFlip,
        ColorButton,
        FontNameSelect,
        FontSizeSelect
    },
    setup() {
        const store = useStore()
        const handleElement = computed<PPTShapeElement>(
            () => store.getters.handleElement
        )
        const editingShapeElementId = computed(
            () => store.state.editingShapeElementId
        )

        const showTextTools = computed(() => {
            return editingShapeElementId.value === handleElement.value.id
        })

        const fill = ref<string>()
        const gradient = ref<ShapeGradient>()
        const fillType = ref('fill')
        const textAlign = ref('middle')

        watch(
            handleElement,
            () => {
                if (
                    !handleElement.value ||
                    handleElement.value.type !== 'shape'
                ) {
                    return
                }
                fill.value = handleElement.value.fill || '#000'

                gradient.value = handleElement.value.gradient || {
                    type: 'linear',
                    rotate: 0,
                    color: [fill.value, '#fff']
                }

                fillType.value = handleElement.value.gradient
                    ? 'gradient'
                    : 'fill'

                textAlign.value = handleElement.value?.text?.align || 'middle'
            },
            { deep: true, immediate: true }
        )

        const { addHistorySnapshot } = useHistorySnapshot()

        // 设置填充类型：渐变、纯色
        const updateFillType = (type: 'gradient' | 'fill') => {
            if (type === 'fill') {
                store.commit(MutationTypes.REMOVE_ELEMENT_PROPS, {
                    id: handleElement.value.id,
                    propName: 'gradient'
                })
            } else {
                const props = { gradient: gradient.value }
                store.commit(MutationTypes.UPDATE_ELEMENT, {
                    id: handleElement.value.id,
                    props
                })
            }
            addHistorySnapshot()
        }

        // 设置渐变填充
        const updateGradient = (gradientProps: Partial<ShapeGradient>) => {
            const props = { gradient: { ...gradient.value, ...gradientProps } }
            store.commit(MutationTypes.UPDATE_ELEMENT, {
                id: handleElement.value.id,
                props
            })
            addHistorySnapshot()
        }

        // 设置填充色
        const updateFill = (value: string) => {
            const props = { fill: value }
            store.commit(MutationTypes.UPDATE_ELEMENT, {
                id: handleElement.value.id,
                props
            })
            addHistorySnapshot()
        }

        const updateTextAlign = (align: 'top' | 'middle' | 'bottom') => {
            const defaultText: ShapeText = {
                content: '',
                defaultFontName: 'Sans-serif',
                defaultColor: '#000',
                align: 'middle'
            }
            const _text = handleElement.value.text || defaultText
            const props = { text: { ..._text, align } }
            store.commit(MutationTypes.UPDATE_ELEMENT, {
                id: handleElement.value.id,
                props
            })
            addHistorySnapshot()
        }

        const richTextAttrs = computed(() => store.state.richTextAttrs)
        const availableFonts = computed(() => store.state.availableFonts)

        const emitRichTextCommand = (command: string, value?: string) => {
            emitter.emit(EmitterEvents.RICH_TEXT_COMMAND, { command, value })
        }
        const updateTextFontName = (value: string) => {
            emitRichTextCommand('fontname', value)
        }

        return {
            fill,
            gradient,
            fillType,
            textAlign,
            richTextAttrs,
            availableFonts,
            FONT_SIZE_OPTIONS,
            webFonts,
            showTextTools,
            emitRichTextCommand,
            updateFillType,
            updateFill,
            updateGradient,
            updateTextAlign,
            updateTextFontName
        }
    }
})
