
import { computed, defineComponent, PropType } from 'vue'
import { useStore } from '@/store'
import { PPTLinkButtonElement } from '@/types/slides'
import { OperateResizeHandler } from '@/types/edit'
import useCommonOperate from '../hooks/useCommonOperate'

import ResizeHandler from './ResizeHandler.vue'
import BorderLine from './BorderLine.vue'

export default defineComponent({
	name: 'link-button-element-operate',
	inheritAttrs: false,
	components: {
		ResizeHandler,
		BorderLine
	},
	props: {
		elementInfo: {
			type: Object as PropType<PPTLinkButtonElement>,
			required: true
		},
		isActiveGroupElement: {
			type: Boolean,
			required: true
		},
		isMultiSelect: {
			type: Boolean,
			required: true
		},
		scaleElement: {
			type: Function as PropType<
				(
					e: MouseEvent,
					element: PPTLinkButtonElement,
					command: OperateResizeHandler
				) => void
			>,
			required: true
		}
	},
	setup(props) {
		const store = useStore()
		const canvasScale = computed(() => store.state.canvasScale)

		const scaleWidth = computed(
			() => props.elementInfo.width * canvasScale.value
		)
		const scaleHeight = computed(
			() => props.elementInfo.height * canvasScale.value
		)
		const { LinkButtonElementResizeHandlers, borderLines } = useCommonOperate(
			scaleWidth,
			scaleHeight
		)

		return {
			scaleWidth,
			LinkButtonElementResizeHandlers,
			borderLines
		}
	}
})
