
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'text-pool',
	setup(props, { emit }) {
		const selectTextType = (vertical: boolean) => {
			emit('select', vertical)
		}
		return {
			selectTextType
		}
	}
})
