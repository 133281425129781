
import { computed, defineComponent, PropType } from 'vue'
import { useStore } from '@/store'
import { PPTElement } from '@/types/slides'
import useLink from '@/hooks/useLink'

export default defineComponent({
	name: 'link-handler',
	props: {
		elementInfo: {
			type: Object as PropType<PPTElement>,
			required: true
		},
		openLinkDialog: {
			type: Function as PropType<() => void>,
			required: true
		}
	},
	setup() {
		const store = useStore()
		const canvasScale = computed(() => store.state.canvasScale)

		const { removeLink } = useLink()
		return {
			canvasScale,
			removeLink
		}
	}
})
