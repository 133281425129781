
import { computed, defineComponent, onMounted, ref } from 'vue'
import { CloseOutlined } from '@ant-design/icons-vue'
import useModifyRecord from '@/hooks/useModifyRecord'
import { ModifyRecord } from '@/utils/modifyRecordsDatabase'
import ThumbnailSlide from './components/ThumbnailSlide.vue'
import { VIEWPORT_SIZE } from '@/configs/canvas'
import EditableElement from '@/views/Editor/Canvas/EditableElement.vue'
import useSlideBackgroundStyle from '@/hooks/useSlideBackgroundStyle'
import logger from '@evideo/logger'
import { MutationTypes, useStore } from '@/store'
import _ from 'lodash'
import { PptState } from '@/types/pptState'

export default defineComponent({
	name: 'modify-records-modal',
	components: { CloseOutlined, ThumbnailSlide, EditableElement },
	props: {
		pptName: String
	},
	emits: ['update:visible'],
	setup(props, ctx) {
		const { getModifyRecordsList } = useModifyRecord()
		const store = useStore()

		const loading = ref(true)
		const canvasContainerRef = ref()
		const list = ref<ModifyRecord[]>([])
		const currentSlideIndex = ref(0)
		const currentListIndex = ref(0)
		const canvasScale = ref(1)
		const canvasContainerWidth = ref(0)
		const canvasContainerHeight = ref(0)
		const currentRecord = computed(() => {
			if (list.value) {
				return list.value[currentListIndex.value]
			}
			return { slides: [], theme: { viewportRatio: 0.5625 } }
		})

		const title = computed(() => {
			if (list.value && list.value?.[currentListIndex.value]) {
				return `${list.value[currentListIndex.value].time} ${props?.pptName ||
					'未命名课件'}`
			}
			return `${props?.pptName || '未命名课件'}`
		})

		const background = computed(
			() => currentRecord.value?.slides?.[currentSlideIndex.value]?.background
		)

		const canvasBackgroundStyle = computed(() => {
			if (background.value) {
				const { backgroundStyle } = useSlideBackgroundStyle(background)
				return backgroundStyle.value
			}
			return { backgroundColor: '#fff' }
		})

		const handleChangeCurrentListIndex = (index: number) => {
			currentListIndex.value = index
			currentSlideIndex.value = 0
			setTimeout(() => {
				setCanvasScale()
			}, 0)
		}

		const handleChangeCurrentSlideIndex = (index: number) => {
			currentSlideIndex.value = index
		}

		const setCanvasScale = () => {
			if (!canvasContainerRef.value) {
				return
			}
			const canvasContainerRect = canvasContainerRef.value.getBoundingClientRect()

			if (canvasContainerRect) {
				const { width } = canvasContainerRect
				const canvasWidth = Math.floor(width - 50)
				canvasContainerWidth.value = canvasWidth
				canvasContainerHeight.value =
					canvasWidth * (currentRecord.value?.theme?.viewportRatio || 0.5625)
				canvasScale.value = canvasWidth / VIEWPORT_SIZE
			}
		}

		const handleReturn = () => {
			if (list.value.length === 0) {
				return
			}
			logger.info(
				`ppt return to ${list.value[currentListIndex.value].time}, uuid: ${
					list.value[currentListIndex.value].uuid
				}`
			)
			store.commit(MutationTypes.RESET)
			store.commit(MutationTypes.LOAD_PPT, {
				uuid: store.state.uuid,
				slides: _.cloneDeep(currentRecord.value.slides),
				theme: _.cloneDeep(currentRecord.value.theme),
				pptUrl: store.state.pptUrl,
				slideIndex: 0
			})
			store.commit(MutationTypes.SET_PPT_STATE, PptState.MODIFY)

			handleCloseModal()
		}

		const handleCloseModal = () => {
			ctx.emit('update:visible', false)
		}

		const initModifyRecordsModal = () => {
			loading.value = true

			return new Promise((resolve, reject) => {
				getModifyRecordsList()
					.then((res) => {
						if (res && res.length > 0) {
							list.value = res.reverse()
							setCanvasScale()
						}
						setCanvasScale()
						resolve('')
					})
					.catch(reject)
					.finally(() => {
						loading.value = false
					})
			})
		}
		onMounted(() => {
			initModifyRecordsModal()
		})
		return {
			title,
			list,
			handleReturn,
			currentListIndex,
			currentRecord,
			currentSlideIndex,
			canvasContainerRef,
			canvasScale,
			VIEWPORT_SIZE,
			canvasContainerWidth,
			canvasContainerHeight,
			canvasBackgroundStyle,
			handleCloseModal,
			handleChangeCurrentListIndex,
			handleChangeCurrentSlideIndex
		}
	}
})
