import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-1d945f13")
const _hoisted_1 = ["d", "stroke"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgWrapper = _resolveComponent("SvgWrapper")!

  return (_openBlock(), _createBlock(_component_SvgWrapper, { class: "grid-lines" }, {
    default: _withCtx(() => [
      _createElementVNode("path", {
        style: _normalizeStyle({
        transform: `scale(${_ctx.canvasScale})`,
      }),
        d: _ctx.path,
        fill: "none",
        stroke: _ctx.gridColor,
        "stroke-width": "0.3",
        "stroke-dasharray": "5"
      }, null, 12, _hoisted_1)
    ]),
    _: 1
  }))
}