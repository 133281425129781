import CheckLinkDialog from '@/components/CheckLinkDialog.vue'
import { LinePoolItem } from '@/configs/lines'
import { ShapePoolItem } from '@/configs/shapes'
import useCreateElement from '@/hooks/useCreateElement'
import useStatReport, { STAT_EVENT } from '@/hooks/useStatReport'
import useUpLoadResource, { UpLoadReply } from '@/hooks/useUpLoadResource'
import { MutationTypes, useStore } from '@/store'
import { ChartType, ElementTypes, ElfElementTypes } from '@/types/slides'
import logger from '@evideo/logger'
import { message } from 'ant-design-vue'
import {
	Ref,
	computed,
	defineComponent,
	h,
	markRaw,
	onMounted,
	ref,
	watch
} from 'vue'
import ChartPool from '../ChartPool.vue'
import FileInputWithProgress from '../FileInputWithProgress.vue'
// import LinePool from '../LinePool.vue'
import ShapePool from '../ShapePool.vue'
import TableGenerator from '../TableGenerator.vue'
import TextPool from '../TextPool.vue'
import useCanvasToolModal from './useCanvasToolModal'
import useMediaTranscode from '@/hooks/useMediaTranscode'

export type CanvasTool = {
	name: string // 工具名称
	description: string // 工具描述
	icon: string // 工具图标
	group?: string // 分组
	inToolBar?: boolean // 是的在工具栏当中
	shortcutText?: string // 快捷键说明
	shortcutHandler?: (...args: any[]) => void // 快捷键事件
	component?: any // 工具组件
	componentData?: {
		// 工具组件传递的参数
		data?: any
		event?: any
	}
	isPopover?: boolean // 是否弹出框
	popoverComp?: any // 弹出框的内容组件
	popoverCompData?: {
		// 弹出框的内容组件传递的参数
		data?: any
		event?: any
	}
	popoverVisible?: boolean // 弹出框是否显示
	isDownPopover?: boolean // 下拉框图标弹出框
	downPopoverComp?: any // 弹出框的内容组件
	downPopoverCompData?: {
		// 弹出框的内容组件传递的参数
		data?: any
		event?: any
	}
	downPopoverVisible?: boolean // 弹出框是否显示
}

// 定义基本容器组件
const defaultToolComponent = defineComponent({
	name: 'defaultToolComponent',
	setup(_props, ctx) {
		return () => h('div', null, [ctx.slots.default?.()])
	}
})

export default () => {
	const store = useStore()
	const { mediaTranscodeList } = useMediaTranscode()
	const { pptEditStatReport } = useStatReport()
	const { getImageResourceUrl } = useUpLoadResource()
	const {
		createImageElement,
		createElfElement,
		createChartElement,
		createTableElement
	} = useCreateElement()

	const {
		openEnjoySongModal,
		openMusicRhythmModal,
		openQuestionsBankModal,
		openSingPlayModal,
		openPKQuestionsModal,
		openMaterialLibrary
	} = useCanvasToolModal()

	const screening = computed(() => store.state.screening)
	/**
	 * 工具处理函数
	 */
	const toolHandlers = {
		/**
		 * 绘制形状范围
		 * @param shape
		 */
		drawShape: (shape: ShapePoolItem) => {
			store.commit(MutationTypes.SET_CREATING_ELEMENT, {
				type: ElementTypes.SHAPE,
				data: shape
			})
			pptEditStatReport(STAT_EVENT.PPT_EDIT_TOOLBTN_CLICK, {
				element_type: ElementTypes.SHAPE
			})
		},
		/**
		 * 绘制文字范围
		 */
		drawText: (vertical = false) => {
			store.commit(MutationTypes.SET_CREATING_ELEMENT, {
				type: ElementTypes.TEXT,
				vertical,
				content: ``
			})
			pptEditStatReport(STAT_EVENT.PPT_EDIT_TOOLBTN_CLICK, {
				element_type: ElementTypes.TEXT
			})
		},
		/**
		 *  插入图片
		 * @param files
		 * @returns
		 */
		insertImageElement: async (files: File[]) => {
			const imageFile = files[0]
			if (!imageFile) return
			try {
				const res = await getImageResourceUrl(imageFile)
				createImageElement(res.url, res.size, res.md5)
			} catch (e) {
				logger.error(e)
				message.error('插入图片失败')
			}
			pptEditStatReport(STAT_EVENT.PPT_EDIT_TOOLBTN_CLICK, {
				element_type: ElementTypes.IMAGE
			})
		},
		/**
		 * @description: 插入音频
		 * @param {UpLoadReply} res
		 * @return {*}
		 */
		insertAudioElement: (res: UpLoadReply) => {
			createElfElement(ElfElementTypes.AUDIO, {
				src: res.url,
				md5: res.md5,
				size: res.size
			})
			pptEditStatReport(STAT_EVENT.PPT_EDIT_TOOLBTN_CLICK, {
				element_type: ElementTypes.ELF,
				subtype: ElfElementTypes.AUDIO
			})
		},
		/**
		 * @description: 插入视频
		 * @param {UpLoadReply} res
		 * @return {*}
		 */
		insertVideoElement: (res: UpLoadReply) => {
			createElfElement(ElfElementTypes.VIDEO, {
				src: res.url,
				md5: res.md5,
				videoSize: res.size,
				size: res.size,
				name: res.fileName
			})
			pptEditStatReport(STAT_EVENT.PPT_EDIT_TOOLBTN_CLICK, {
				element_type: ElementTypes.ELF,
				subtype: ElfElementTypes.VIDEO
			})
		},
		/**
		 * 绘制线条路径
		 * @param line
		 */
		drawLine: (line: LinePoolItem) => {
			store.commit(MutationTypes.SET_CREATING_ELEMENT, {
				type: ElementTypes.LINE,
				data: line
			})
			pptEditStatReport(STAT_EVENT.PPT_EDIT_TOOLBTN_CLICK, {
				element_type: ElementTypes.LINE
			})
		},
		/**
		 * 插入谱例歌曲
		 */
		insertSingPlay: () => {
			openSingPlayModal().then((data: any) => {
				logger.info('insert sing-play', data)
				createElfElement(ElfElementTypes.SING_PLAY, data)
				pptEditStatReport(STAT_EVENT.PPT_EDIT_TOOLBTN_CLICK, {
					element_type: ElementTypes.ELF,
					subtype: ElfElementTypes.SING_PLAY
				})
			})
		},
		/**
		 * 插入欣赏歌曲
		 */
		insertEnjoySong: () => {
			openEnjoySongModal().then((data) => {
				logger.info('insert enjoy-song', data)
				createElfElement(ElfElementTypes.ENJOY, data)
				pptEditStatReport(STAT_EVENT.PPT_EDIT_TOOLBTN_CLICK, {
					element_type: ElementTypes.ELF,
					subtype: ElfElementTypes.ENJOY
				})
			})
		},
		/**
		 * 插入答题PK
		 */
		insertPKQuestions: () => {
			openPKQuestionsModal().then((data: any) => {
				logger.info('insert PK-questions', data)
				createElfElement(ElfElementTypes.PK_QUESTIONS, data)
				pptEditStatReport(STAT_EVENT.PPT_EDIT_TOOLBTN_CLICK, {
					element_type: ElementTypes.ELF,
					subtype: ElfElementTypes.PK_QUESTIONS
				})
			})
		},
		/**
		 * 插入题库
		 */
		insertQuestionsBank: () => {
			openQuestionsBankModal().then((data: any) => {
				logger.info('insert questions-bank', data)
				// if (data?.source === 'teacher') {
				// 	data.source = 'school'
				// }
				createElfElement(ElfElementTypes.QUESTIONS_BANK, data)
				pptEditStatReport(STAT_EVENT.PPT_EDIT_TOOLBTN_CLICK, {
					element_type: ElementTypes.ELF,
					subtype: ElfElementTypes.QUESTIONS_BANK
				})
			})
		},
		/**
		 * 插入超链接按钮
		 */
		insertLinkButton: () => {
			store.commit(MutationTypes.SET_CREATING_ELEMENT, {
				type: ElementTypes.LINKBUTTON
			})
			pptEditStatReport(STAT_EVENT.PPT_EDIT_TOOLBTN_CLICK, {
				element_type: ElementTypes.LINKBUTTON
			})
		},
		/**
		 * 插入音乐律动
		 */
		insertMusicRhythm: () => {
			openMusicRhythmModal().then((data: any) => {
				logger.info('insert music-rhythm', data)
				createElfElement(ElfElementTypes.MUSIC_RHYTHM, data)
				pptEditStatReport(STAT_EVENT.PPT_EDIT_TOOLBTN_CLICK, {
					element_type: ElementTypes.ELF,
					subtype: ElfElementTypes.MUSIC_RHYTHM
				})
			})
		},
		insertMaterial: () => {
			openMaterialLibrary().then(() => {
				pptEditStatReport(STAT_EVENT.PPT_EDIT_TOOLBTN_CLICK, {
					type: 'material'
				})
			})
		}
	}

	const canvasTools: Ref<CanvasTool[]> = ref([
		{
			name: '形状',
			description: '选择图形并添加到页面中',
			icon: 'canvas-tool-icon-shape',
			inToolBar: true,
			component: markRaw(defaultToolComponent),
			isPopover: true,
			popoverComp: markRaw(ShapePool),
			popoverCompData: {
				event: {
					select: function (this: any, shape: ShapePoolItem | LinePoolItem, elementType: string) {
						if (elementType === 'line') {
							toolHandlers.drawLine(shape as LinePoolItem)
							this.popoverVisible = false
						} else {
							toolHandlers.drawShape(shape as ShapePoolItem)
							this.popoverVisible = false
						}

					}
				}
			}
		},
		{
			name: '文本',
			description: '在页面中添加文本框',
			icon: 'canvas-tool-icon-text',
			inToolBar: true,
			component: markRaw(defaultToolComponent),
			componentData: {
				event: {
					click: () => toolHandlers.drawText()
				}
			},
			isDownPopover: true,
			downPopoverComp: markRaw(TextPool),
			downPopoverCompData: {
				event: {
					select: function(this: any, vertical: boolean) {
						toolHandlers.drawText(vertical)
						this.downPopoverVisible = false
					}
				}
			}
		},
		{
			name: '图片',
			description: '选择图片文件并添加到页面中',
			icon: 'canvas-tool-icon-image',
			inToolBar: true,
			// component: markRaw(FileInput),
			component: markRaw(FileInputWithProgress),
			componentData: {
				event: {
					change: (files: File[]) => toolHandlers.insertImageElement(files)
				},
				data: {
					type: 'Image'
				}
			}
		},
		{
			name: '音频',
			description: '选择音频文件并添加到页面中',
			icon: 'canvas-tool-icon-audio',
			inToolBar: true,
			// component: markRaw(defaultToolComponent),
			component: markRaw(FileInputWithProgress),
			componentData: {
				event: {
					// click: () => createElfElement('elf-audio')
					// 点击上传完成
					ok: (res: UpLoadReply) => toolHandlers.insertAudioElement(res)
				},
				data: {
					type: 'Audio',
					accept: 'audio/*'
				}
			}
		},
		{
			name: '视频',
			description: '选择视频文件并添加到页面中',
			icon: 'canvas-tool-icon-video',
			inToolBar: true,
			// component: markRaw(defaultToolComponent),
			component: markRaw(FileInputWithProgress),
			componentData: {
				event: {
					// click: () => createElfElement('elf-video')
					// 点击上传完成
					ok: (res: UpLoadReply) => toolHandlers.insertVideoElement(res)
				},
				data: {
					type: 'Video',
					accept: 'video/*'
				}
			}
		},
		{
			name: '音乐律动',
			description: '选择歌曲并添加音乐律动到页面中',
			icon: 'canvas-tool-icon-music-rhythm',
			inToolBar: true,
			component: markRaw(defaultToolComponent),
			componentData: {
				event: {
					click: () => toolHandlers.insertMusicRhythm()
				}
			}
		},
		{
			name: '曲谱',
			description: '选择曲谱并添加到页面中',
			icon: 'canvas-tool-icon-sing',
			inToolBar: true,
			component: markRaw(defaultToolComponent),
			componentData: {
				event: {
					click: () => toolHandlers.insertSingPlay()
				}
			}
		},
		{
			name: '教材欣赏',
			description: '选择欣赏歌曲并添加到页面中',
			icon: 'canvas-tool-icon-enjoy-song',
			inToolBar: true,
			component: markRaw(defaultToolComponent),
			componentData: {
				event: {
					click: () => toolHandlers.insertEnjoySong()
				}
			}
		},
		{
			name: '答题PK',
			description: '选择答题PK题目组并添加到页面中',
			icon: 'canvas-tool-icon-pk-questions',
			inToolBar: true,
			component: markRaw(defaultToolComponent),
			componentData: {
				event: {
					click: () => toolHandlers.insertPKQuestions()
				}
			}
		},
		{
			name: '素材库',
			description: '选择素材并添加到页面中',
			icon: 'canvas-tool-icon-material-library',
			component: markRaw(defaultToolComponent),
			componentData: {
				event: {
					click: () => toolHandlers.insertMaterial()
				}
			}
		},
		// {
		// 	name: '线条箭头',
		// 	description: '选择线条箭头并添加到页面中',
		// 	icon: 'canvas-tool-icon-line',
		// 	component: markRaw(defaultToolComponent),
		// 	isPopover: true,
		// 	popoverComp: markRaw(LinePool),
		// 	popoverCompData: {
		// 		event: {
		// 			select: function(this: any, line: LinePoolItem) {
		// 				toolHandlers.drawLine(line)
		// 				this.popoverVisible = false
		// 			}
		// 		}
		// 	}
		// },
		{
			name: '图表',
			description: '选择图表并到页面中',
			icon: 'canvas-tool-icon-chart',
			component: markRaw(defaultToolComponent),
			isPopover: true,
			popoverComp: markRaw(ChartPool),
			popoverCompData: {
				event: {
					select: function(this: any, chartType: ChartType) {
						createChartElement(chartType)
						this.popoverVisible = false
						pptEditStatReport(STAT_EVENT.PPT_EDIT_TOOLBTN_CLICK, {
							element_type: ElementTypes.CHART
						})
					}
				}
			}
		},
		{
			name: '表格',
			description: '在页面中添加表格和制作表格',
			icon: 'canvas-tool-icon-table',
			component: markRaw(defaultToolComponent),
			isPopover: true,
			popoverComp: markRaw(TableGenerator),
			popoverCompData: {
				event: {
					close: function(this: any) {
						this.popoverVisible = false
					},
					insert: function(
						this: any,
						{ row, col }: { row: number; col: number }
					) {
						createTableElement(row, col)
						this.popoverVisible = false
						pptEditStatReport(STAT_EVENT.PPT_EDIT_TOOLBTN_CLICK, {
							element_type: ElementTypes.TABLE
						})
					}
				}
			}
		},
		{
			name: '轮播图',
			description: '在页面中添加轮播图',
			icon: 'canvas-tool-icon-carouse-image',
			component: markRaw(defaultToolComponent),
			componentData: {
				event: {
					click: () => {
						createElfElement(ElfElementTypes.CAROUSE_IMAGE)
						pptEditStatReport(STAT_EVENT.PPT_EDIT_TOOLBTN_CLICK, {
							element_type: ElementTypes.ELF,
							subtype: ElfElementTypes.CAROUSE_IMAGE
						})
					}
				}
			}
		},
		{
			name: '超链接',
			description: '在页面中添加超链接按钮',
			icon: 'canvas-tool-icon-link-button',
			component: markRaw(CheckLinkDialog),
			componentData: {
				event: {
					click: () => toolHandlers.insertLinkButton()
				}
			}
		},
		{
			name: '钢琴合奏',
			description: '添加钢琴合奏到页面中',
			icon: 'canvas-tool-icon-emsemble-practice',
			component: markRaw(defaultToolComponent),
			componentData: {
				event: {
					click: () => {
						createElfElement(ElfElementTypes.ENSEMBLE_PRACTICE)
						pptEditStatReport(STAT_EVENT.PPT_EDIT_TOOLBTN_CLICK, {
							element_type: ElementTypes.ELF,
							subtype: ElfElementTypes.ENSEMBLE_PRACTICE
						})
					}
				}
			}
		},
		{
			name: '题库',
			description: '添加题库到页面中',
			icon: 'canvas-tool-icon-questions-bank',
			inToolBar: false,
			component: markRaw(defaultToolComponent),
			componentData: {
				event: {
					click: () => toolHandlers.insertQuestionsBank()
				}
			}
		}
	])

	const changeToolsEventFuncThisDirect = () => {
		const bindThis = (func: any, that: any) => func.bind(that)
		const bindFunction = (funcObject: any = {}, thatObject: any = {}) => {
			return Object.entries(funcObject).reduce((acc, [key, value]) => {
				if (value instanceof Function) {
					acc[key] = bindThis(value, thatObject)
				} else {
					acc[key] = value
				}
				return acc
			}, {})
		}
		canvasTools.value.forEach((tool: CanvasTool) => {
			if (tool.componentData && tool.componentData.event) {
				tool.componentData.event = bindFunction(tool.componentData.event, tool)
			}
			if (tool.popoverCompData && tool.popoverCompData.event) {
				tool.popoverCompData.event = bindFunction(
					tool.popoverCompData.event,
					tool
				)
			}
			if (tool.downPopoverCompData && tool.downPopoverCompData.event) {
				tool.downPopoverCompData.event = bindFunction(
					tool.downPopoverCompData.event,
					tool
				)
			}
			if (tool.downPopoverCompData && tool.downPopoverCompData.event) {
				tool.downPopoverCompData.event = bindFunction(
					tool.downPopoverCompData.event,
					tool
				)
			}
		})
	}

	const insertMediaElement = () => {
		mediaTranscodeList.value.forEach((item) => {
			if (item.result && !item?.result?.rendered) {
				const { url, size, md5 } = item.result.uploadReply
				switch (item.type) {
					case 'image':
						createImageElement(url, size, md5)
						pptEditStatReport(STAT_EVENT.PPT_EDIT_TOOLBTN_CLICK, {
							element_type: ElementTypes.IMAGE
						})
						break
					case 'audio':
						toolHandlers.insertAudioElement(item.result.uploadReply)
						break
					case 'video':
						toolHandlers.insertVideoElement({
							...item.result.uploadReply,
							fileName: item?.name
						})
						break
					default:
						break
				}
				item.result['rendered'] = true
				item['slideId'] = store.state.slides[store.state.slideIndex].id
			}
		})
	}

	watch(
		() => [mediaTranscodeList.value, screening.value],
		() => {
			if (screening.value) {
				return
			}
			if (!mediaTranscodeList.value) {
				return
			}
			const isExitNoRenderIndex = mediaTranscodeList.value?.findIndex(
				(item) => item.result && !item.result?.rendered
			)
			if (isExitNoRenderIndex >= 0) {
				insertMediaElement()
			}
		},
		{ deep: true, immediate: true }
	)

	onMounted(() => {
		changeToolsEventFuncThisDirect()
	})

	return {
		canvasTools
	}
}
