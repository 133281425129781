
import { computed, defineComponent } from 'vue'
import { useStore } from '@/store'
import { SlideBackground } from '@/types/slides'
import GridLines from './GridLines.vue'
import useSlideBackgroundStyle from '@/hooks/useSlideBackgroundStyle'

export default defineComponent({
  name: 'viewport-background',
  components: {
    GridLines,
  },
  setup() {
    const store = useStore()
    const showGridLines = computed(() => store.state.showGridLines)
    const background = computed<SlideBackground | undefined>(() => store.getters.currentSlide?.background)

    const { backgroundStyle } = useSlideBackgroundStyle(background)

    return {
      showGridLines,
      backgroundStyle,
    }
  },
})
