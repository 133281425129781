
import { computed, defineComponent, ref, watch } from 'vue'
import { MutationTypes, useStore } from '@/store'
import { PPTTextElement } from '@/types/slides'
import emitter, { EmitterEvents, RichTextCommand } from '@/utils/emitter'
import { WEB_FONTS, FONT_SIZE_OPTIONS } from '@/configs/font'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'

// import ElementOpacity from '../common/ElementOpacity.vue'
import ElementOutline from '../common/ElementOutline.vue'
import ElementShadow from '../common/ElementShadow.vue'
import FontNameSelect from '../common/FontNameSelect.vue'
import FontSizeSelect from '../common/FontSizeSelect.vue'

const presetStyles = [
    {
        label: '大标题',
        style: {
            fontSize: '26px',
            fontWeight: 700
        },
        cmd: [
            { command: 'clear' },
            { command: 'fontsize', value: '48px' },
            { command: 'align', value: 'center' },
            { command: 'bold' }
        ]
    },
    {
        label: '小标题',
        style: {
            fontSize: '22px',
            fontWeight: 700
        },
        cmd: [
            { command: 'clear' },
            { command: 'fontsize', value: '36px' },
            { command: 'align', value: 'center' },
            { command: 'bold' }
        ]
    },
    {
        label: '正文',
        style: {
            fontSize: '20px'
        },
        cmd: [{ command: 'clear' }, { command: 'fontsize', value: '20px' }]
    },
    {
        label: '正文[小]',
        style: {
            fontSize: '18px'
        },
        cmd: [{ command: 'clear' }, { command: 'fontsize', value: '18px' }]
    },
    {
        label: '注释 1',
        style: {
            fontSize: '16px',
            fontStyle: 'italic'
        },
        cmd: [
            { command: 'clear' },
            { command: 'fontsize', value: '16px' },
            { command: 'em' }
        ]
    },
    {
        label: '注释 2',
        style: {
            fontSize: '16px',
            textDecoration: 'underline'
        },
        cmd: [
            { command: 'clear' },
            { command: 'fontsize', value: '16px' },
            { command: 'underline' }
        ]
    }
]

const webFonts = WEB_FONTS

export default defineComponent({
    name: 'text-style-panel',
    components: {
        // ElementOpacity,
        ElementOutline,
        ElementShadow,
        FontNameSelect,
        FontSizeSelect
    },
    setup() {
        const store = useStore()
        const handleElement = computed<PPTTextElement>(
            () => store.getters.handleElement
        )
        const richTextAttrs = computed(() => store.state.richTextAttrs)

        const fill = ref<string>()
        const lineHeight = ref<number>()
        const wordSpace = ref<number>()

        watch(
            handleElement,
            () => {
                if (
                    !handleElement.value ||
                    handleElement.value.type !== 'text'
                ) {
                    return
                }

                fill.value = handleElement.value.fill || '#000'
                lineHeight.value = handleElement.value.lineHeight || 1.5
                wordSpace.value = handleElement.value.wordSpace || 0
            },
            { deep: true, immediate: true }
        )

        const lineHeightOptions = [
            0.3,
            0.4,
            0.5,
            0.6,
            0.7,
            0.8,
            0.9,
            1.0,
            1.15,
            1.2,
            1.4,
            1.5,
            1.8,
            2.0,
            2.5,
            3.0
        ]
        const wordSpaceOptions = [
            0,
            1,
            2,
            3,
            4,
            5,
            6,
            8,
            10,
            11,
            12,
            13,
            14,
            15
        ]
        // 发射富文本设置命令
        const emitRichTextCommand = (command: string, value?: string) => {
            emitter.emit(EmitterEvents.RICH_TEXT_COMMAND, { command, value })
        }

        // 发射富文本设置命令（批量）
        const emitBatchRichTextCommand = (payload: RichTextCommand[]) => {
            emitter.emit(EmitterEvents.RICH_TEXT_COMMAND, payload)
        }

        const { addHistorySnapshot } = useHistorySnapshot()

        // 设置行高
        const updateLineHeight = (value: number) => {
            const props = { lineHeight: value }
            store.commit(MutationTypes.UPDATE_ELEMENT, {
                id: handleElement.value.id,
                props
            })
            addHistorySnapshot()
        }

        // 设置字间距
        const updateWordSpace = (value: number) => {
            const props = { wordSpace: value }
            store.commit(MutationTypes.UPDATE_ELEMENT, {
                id: handleElement.value.id,
                props
            })
            addHistorySnapshot()
        }

        // 设置文本框填充
        const updateFill = (value: string) => {
            const props = { fill: value }
            store.commit(MutationTypes.UPDATE_ELEMENT, {
                id: handleElement.value.id,
                props
            })
            addHistorySnapshot()
        }

        const updateTextFontName = (value: string) => {
            emitRichTextCommand('fontname', value)
        }

        return {
            fill,
            lineHeight,
            wordSpace,
            richTextAttrs,
            webFonts,
            FONT_SIZE_OPTIONS,
            lineHeightOptions,
            wordSpaceOptions,
            updateLineHeight,
            updateWordSpace,
            updateFill,
            updateTextFontName,
            emitRichTextCommand,
            emitBatchRichTextCommand,
            presetStyles
        }
    }
})
