
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useStore } from '@/store'
import { PPTElement } from '@/types/slides'
import useLink from '@/hooks/useLink'
import { fillDigit } from '@/utils/common'

const LinkMap = {
	ADDRESS: 'web-url',
	SLIDE: 'slide'
}
const thumbnailWith = 150
const slideCurrentWidth = 440
export default defineComponent({
	name: 'link-dialog',
	setup(props, { emit }) {
		const store = useStore()
		const handleElement = computed<PPTElement | null>(
			() => store.getters.handleElement
		)
		const activeKey = ref(LinkMap.ADDRESS)
		const viewportRatio = computed(() => store.state.viewportRatio)
		const slides = computed(() => store.state.slides)
		const linkSlideIndex = ref(0)

		const link = ref('')
		const linkDialogSlideList = ref<HTMLElement>()

		const { setLink, setLinkSlideId } = useLink()

		const handleChangeActiveSlideIndex = (selectedIndex: number) => {
			linkSlideIndex.value = selectedIndex
		}

		const slideIndex = store.state.slideIndex
		handleChangeActiveSlideIndex(slideIndex)	

		watch(
			() => linkDialogSlideList.value,
			() => {
				const scrollYOff =
					thumbnailWith * viewportRatio.value * linkSlideIndex.value
				linkDialogSlideList.value?.scrollBy(0, scrollYOff)
			}
		)

		onMounted(() => {
			if (handleElement.value?.link) {
				if (handleElement.value?.link.type === 'slide') {
					const linkSlide = +handleElement.value.link.target.split('-')?.[0]
					linkSlideIndex.value = linkSlide
					activeKey.value = LinkMap.SLIDE
				} else {
					link.value = handleElement.value.link.target
					activeKey.value = LinkMap.ADDRESS
				}
			}
		})

		const close = () => emit('close')

		const save = () => {
			if (activeKey.value === 'web-url') {
				if (handleElement.value) {
					const success = setLink(handleElement.value, link.value)
					if (success) close()
					else link.value = ''
				}
			} else {
				const slide = JSON.parse(
					JSON.stringify(slides.value?.[linkSlideIndex.value])
				)
				const slideId = slide?.id || ''
				if (handleElement.value) {
					const success = setLinkSlideId(
						handleElement.value,
						linkSlideIndex.value + '-' + slideId
					)
					if (success) {
						close()
					} else {
						linkSlideIndex.value = 0
					}
				}
			}
		}

		return {
			link,
			close,
			save,
			activeKey,
			fillDigit,
			viewportRatio,
			thumbnailWith,
			slides,
			slideCurrentWidth,
			linkSlideIndex,
			handleChangeActiveSlideIndex,
			linkDialogSlideList
		}
	}
})
