import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-7fb667aa")
const _hoisted_1 = { class: "chart-pool" }
const _hoisted_2 = ["onClick"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconChartLine = _resolveComponent("IconChartLine")!
  const _component_IconChartHistogram = _resolveComponent("IconChartHistogram")!
  const _component_IconChartPie = _resolveComponent("IconChartPie")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartList, (chart, index) => {
      return (_openBlock(), _createElementBlock("li", {
        class: "chart-item",
        key: index
      }, [
        _createElementVNode("div", {
          class: "chart-content",
          onClick: ($event: any) => (_ctx.selectChart(chart))
        }, [
          (chart === 'line')
            ? (_openBlock(), _createBlock(_component_IconChartLine, {
                key: 0,
                size: "24"
              }))
            : (chart === 'bar')
              ? (_openBlock(), _createBlock(_component_IconChartHistogram, {
                  key: 1,
                  size: "24"
                }))
              : (chart === 'pie')
                ? (_openBlock(), _createBlock(_component_IconChartPie, {
                    key: 2,
                    size: "24"
                  }))
                : _createCommentVNode("", true)
        ], 8, _hoisted_2)
      ]))
    }), 128))
  ]))
}