import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-aa2dc2ee")
const _hoisted_1 = { class: "pptist-editor" }
const _hoisted_2 = { class: "layout-content" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditorHeader = _resolveComponent("EditorHeader")!
  const _component_Thumbnails = _resolveComponent("Thumbnails")!
  const _component_CanvasTool = _resolveComponent("CanvasTool")!
  const _component_Canvas = _resolveComponent("Canvas")!
  const _component_Toolbar = _resolveComponent("Toolbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_EditorHeader, {
      class: "layout-header",
      isAutoSaving: _ctx.isAutoSaving,
      isAutoSavingAbort: _ctx.isAutoSavingAbort
    }, null, 8, ["isAutoSaving", "isAutoSavingAbort"]),
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.knowledgePointsShow)
        ? (_openBlock(), _createBlock(_component_Thumbnails, {
            key: 0,
            class: "layout-content-left"
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: "layout-content-center",
        style: _normalizeStyle({
					width:
						!_ctx.knowledgePointsShow && !_ctx.hiddenToolBar
							? 'calc(100% - 200px - 330px)'
							: 'calc(100% - 200px)'
				})
      }, [
        _createVNode(_component_CanvasTool, { class: "center-top" }),
        _createVNode(_component_Canvas, {
          class: "center-body",
          style: _normalizeStyle({ height: `calc(100% - ${_ctx.remarkHeight + 60}px)` })
        }, null, 8, ["style"])
      ], 4),
      _createVNode(_component_Toolbar, {
        class: "layout-content-right",
        style: _normalizeStyle({
					width: !_ctx.hiddenToolBar ? '330px' : '50px'
				}),
        changeHiddenToolBar: _ctx.changeHiddenToolBar
      }, null, 8, ["style", "changeHiddenToolBar"])
    ])
  ]))
}