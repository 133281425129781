import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-746ec9d0")
const _hoisted_1 = { class: "operates" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElementCreateSelection = _resolveComponent("ElementCreateSelection")!
  const _component_AlignmentLine = _resolveComponent("AlignmentLine")!
  const _component_MultiSelectOperate = _resolveComponent("MultiSelectOperate")!
  const _component_Operate = _resolveComponent("Operate")!
  const _component_ViewportBackground = _resolveComponent("ViewportBackground")!
  const _component_MouseSelection = _resolveComponent("MouseSelection")!
  const _component_EditableElement = _resolveComponent("EditableElement")!
  const _component_LinkDialog = _resolveComponent("LinkDialog")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_LinkButtonDialog = _resolveComponent("LinkButtonDialog")!
  const _directive_contextmenu = _resolveDirective("contextmenu")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "canvas",
    ref: "canvasRef",
    onMousewheel: _cache[5] || (_cache[5] = ($event) => _ctx.handleMousewheelCanvas($event)),
    onMousedown: _cache[6] || (_cache[6] = ($event) => _ctx.handleClickBlankArea($event))
  }, [
    (_ctx.creatingElement)
      ? (_openBlock(), _createBlock(_component_ElementCreateSelection, {
          key: 0,
          style: _normalizeStyle(
				_ctx.creatingElement?.type === 'animation-path' || (_ctx.creatingElement as CreatingLineElement)?.data?.isCustom
					? {
							width: _ctx.viewportStyles.width * _ctx.canvasScale + 'px',
							height: _ctx.viewportStyles.height * _ctx.canvasScale + 'px',
							left: _ctx.viewportStyles.left + 'px',
							top: _ctx.viewportStyles.top + 'px'
					  }
					: {}
			),
          containerWidth: _ctx.viewportStyles.width * _ctx.canvasScale ,
          containerHeight: _ctx.viewportStyles.height * _ctx.canvasScale,
          containerLeft: _ctx.viewportStyles.left,
          containerTop: _ctx.viewportStyles.top,
          onCreated: _cache[0] || (_cache[0] = (data) => _ctx.insertElementFromCreateSelection(data))
        }, null, 8, ["style", "containerWidth", "containerHeight", "containerLeft", "containerTop"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "viewport-wrapper editor-slide-warpper",
      style: _normalizeStyle({
				width: _ctx.viewportStyles.width * _ctx.canvasScale + 'px',
				height: _ctx.viewportStyles.height * _ctx.canvasScale + 'px',
				left: _ctx.viewportStyles.left + 'px',
				top: _ctx.viewportStyles.top + 'px'
			}),
      ref: "viewportWrapper"
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alignmentLines, (line, index) => {
          return (_openBlock(), _createBlock(_component_AlignmentLine, {
            key: index,
            type: line.type,
            axis: line.axis,
            length: line.length
          }, null, 8, ["type", "axis", "length"]))
        }), 128)),
        (_ctx.activeElementIdList.length > 1)
          ? (_openBlock(), _createBlock(_component_MultiSelectOperate, {
              key: 0,
              elementList: _ctx.elementList,
              scaleMultiElement: _ctx.scaleMultiElement
            }, null, 8, ["elementList", "scaleMultiElement"]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elementList, (element, index) => {
          return (_openBlock(), _createBlock(_component_Operate, {
            key: element.id,
            elementInfo: element,
            "element-index": index,
            isSelected: _ctx.activeElementIdList.includes(element.id),
            isActive: _ctx.handleElementId === element.id,
            isActiveGroupElement: _ctx.activeGroupElementId === element.id,
            isMultiSelect: _ctx.activeElementIdList.length > 1,
            rotateElement: _ctx.rotateElement,
            scaleElement: _ctx.scaleElement,
            openLinkDialog: _ctx.openLinkDialog,
            dragLineElement: _ctx.dragLineElement,
            moveShapeKeypoint: _ctx.moveShapeKeypoint,
            selectElement: _ctx.selectElement
          }, null, 8, ["elementInfo", "element-index", "isSelected", "isActive", "isActiveGroupElement", "isMultiSelect", "rotateElement", "scaleElement", "openLinkDialog", "dragLineElement", "moveShapeKeypoint", "selectElement"]))
        }), 128)),
        _createVNode(_component_ViewportBackground)
      ]),
      _createElementVNode("div", {
        class: "viewport",
        ref: "viewportRef",
        style: _normalizeStyle({ transform: `scale(${_ctx.canvasScale})` })
      }, [
        (_ctx.mouseSelectionState.isShow)
          ? (_openBlock(), _createBlock(_component_MouseSelection, {
              key: 0,
              top: _ctx.mouseSelectionState.top,
              left: _ctx.mouseSelectionState.left,
              width: _ctx.mouseSelectionState.width,
              height: _ctx.mouseSelectionState.height,
              quadrant: _ctx.mouseSelectionState.quadrant
            }, null, 8, ["top", "left", "width", "height", "quadrant"]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elementList, (element, index) => {
          return (_openBlock(), _createBlock(_component_EditableElement, {
            key: element.id,
            elementInfo: element,
            elementIndex: index + 1,
            isMultiSelect: _ctx.activeElementIdList.length > 1,
            selectElement: _ctx.selectElement,
            openLinkDialog: _ctx.openLinkDialog,
            openLinkButtonDialog: _ctx.openLinkButtonDialog
          }, null, 8, ["elementInfo", "elementIndex", "isMultiSelect", "selectElement", "openLinkDialog", "openLinkButtonDialog"]))
        }), 128))
      ], 4)
    ], 4),
    _createVNode(_component_Modal, {
      visible: _ctx.linkDialogVisible,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => (_ctx.linkDialogVisible = $event)),
      footer: null,
      centered: "",
      width: 750,
      destroyOnClose: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_LinkDialog, {
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.linkDialogVisible = false))
        })
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Modal, {
      visible: _ctx.linkButtonDialogVisible,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => (_ctx.linkButtonDialogVisible = $event)),
      footer: null,
      centered: "",
      width: 750,
      destroyOnClose: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_LinkButtonDialog, {
          onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.linkButtonDialogVisible = false))
        })
      ]),
      _: 1
    }, 8, ["visible"])
  ], 544)), [
    [_directive_contextmenu, _ctx.contextmenus],
    [_directive_click_outside, _ctx.removeEditorAreaFocus]
  ])
}