
import { PropType, computed, defineComponent } from 'vue'
import ThumbnailElement from '@/views/components/ThumbnailSlide/ThumbnailElement.vue'
import { Slide } from '@/types/slides'
import useSlideBackgroundStyle from '@/hooks/useSlideBackgroundStyle'
import { VIEWPORT_SIZE } from '@/configs/canvas'

export default defineComponent({
	name: 'thumbnail-slide',
	components: {
		ThumbnailElement
	},
	props: {
		slide: {
			type: Object as PropType<Slide>,
			required: true
		},
		size: {
			type: Number,
			required: true
		},
		viewportRatio: {
			type: Number,
			required: true
		}
	},
	setup(props) {
		const background = computed(() => props.slide.background)

		const { backgroundStyle } = useSlideBackgroundStyle(background)
		const scale = computed(() => props.size / VIEWPORT_SIZE)
		return { backgroundStyle, VIEWPORT_SIZE, scale }
	}
})
