
import { computed, defineComponent } from 'vue'
import { useStore } from '@/store'
import { ElementTypes, PPTElement } from '@/types/slides'

import TextStylePanel from './TextStylePanel.vue'
import ImageStylePanel from './ImageStylePanel.vue'
import ShapeStylePanel from './ShapeStylePanel.vue'
import LineStylePanel from './LineStylePanel.vue'
import ChartStylePanel from './ChartStylePanel/index.vue'
import TableStylePanel from './TableStylePanel.vue'
import ElfStylePanel from './ElfStylePanel.vue'

export default defineComponent({
  name: 'element-style-panel',
  setup() {
    const store = useStore()
    const handleElement = computed<PPTElement>(() => store.getters.handleElement)

    const currentPanelComponent = computed(() => {
      if (!handleElement.value) return null

      const panelMap = {
        [ElementTypes.TEXT]: TextStylePanel,
        [ElementTypes.IMAGE]: ImageStylePanel,
        [ElementTypes.SHAPE]: ShapeStylePanel,
        [ElementTypes.LINE]: LineStylePanel,
        [ElementTypes.CHART]: ChartStylePanel,
        [ElementTypes.TABLE]: TableStylePanel,
        [ElementTypes.ELF]: ElfStylePanel,
        [ElementTypes.LINKBUTTON]: ElfStylePanel
      }
      return panelMap[handleElement.value.type] || null
    })

    return {
      handleElement,
      currentPanelComponent,
    }
  },
})
