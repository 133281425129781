import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-6d6c4586")
const _hoisted_1 = {
  key: 1,
  class: "animation-index"
}
const _hoisted_2 = { class: "index-item" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinkHandler = _resolveComponent("LinkHandler")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["operate", { 'multi-select': _ctx.isMultiSelect && !_ctx.isActive }]),
    style: _normalizeStyle({
        top: _ctx.elementInfo.top * _ctx.canvasScale + 'px',
        left: _ctx.elementInfo.left * _ctx.canvasScale + 'px',
        transform: `rotate(${_ctx.elementInfo.rotate}deg)`,
        transformOrigin: `${(_ctx.elementInfo.width * _ctx.canvasScale) /
            2}px ${(_ctx.elementInfo.height * _ctx.canvasScale) / 2}px`
    })
  }, [
    (_ctx.isSelected)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentOperateComponent), {
          key: 0,
          elementInfo: _ctx.elementInfo,
          isActiveGroupElement: _ctx.isActiveGroupElement,
          isMultiSelect: _ctx.isMultiSelect,
          rotateElement: _ctx.rotateElement,
          scaleElement: _ctx.scaleElement,
          dragLineElement: _ctx.dragLineElement,
          selectElement: _ctx.selectElement,
          moveShapeKeypoint: _ctx.moveShapeKeypoint
        }, null, 8, ["elementInfo", "isActiveGroupElement", "isMultiSelect", "rotateElement", "scaleElement", "dragLineElement", "selectElement", "moveShapeKeypoint"]))
      : _createCommentVNode("", true),
    (_ctx.toolbarState === 'elAnimation' &&
        _ctx.elementIndexListInAnimation.length
        )
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elementIndexListInAnimation, (index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "index-item",
              key: index
            }, _toDisplayString(index + 1), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.toolbarState === 'elAnimation')
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "element-index",
          style: _normalizeStyle({
        top: _ctx.elementInfo.type === 'line' ? (-15 + 'px') : (_ctx.elementInfo.height * _ctx.canvasScale + 5) + 'px',
        right: (-_ctx.elementInfo.width * _ctx.canvasScale + 46) + 'px'
    })
        }, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.elementTypeIndex), 1)
        ], 4))
      : _createCommentVNode("", true),
    (_ctx.isActive &&
        _ctx.elementInfo.link &&
        _ctx.elementInfo.type != 'link-button'
        )
      ? (_openBlock(), _createBlock(_component_LinkHandler, {
          key: 3,
          elementInfo: _ctx.elementInfo,
          openLinkDialog: _ctx.openLinkDialog,
          onMousedown: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
        }, null, 8, ["elementInfo", "openLinkDialog"]))
      : _createCommentVNode("", true)
  ], 6))
}