
import { computed, defineComponent, ref, watch } from 'vue'
import { MutationTypes, useStore } from '@/store'
import { PPTElement, PPTElementShadow } from '@/types/slides'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'

import ColorButton from './ColorButton.vue'

export default defineComponent({
  name: 'element-shadow',
  components: {
    ColorButton,
  },
  setup() {
    const store = useStore()
    const handleElement = computed<PPTElement>(() => store.getters.handleElement)

    const shadow = ref<PPTElementShadow>()
    const hasShadow = ref(false)

    watch(handleElement, () => {
      if (!handleElement.value) return
      shadow.value = 'shadow' in handleElement.value ? handleElement.value.shadow : undefined
      hasShadow.value = !!shadow.value
    }, { deep: true, immediate: true })

    const { addHistorySnapshot } = useHistorySnapshot()

    const updateShadow = (shadowProps: Partial<PPTElementShadow>) => {
      const props = { shadow: { ...shadow.value, ...shadowProps } }
      store.commit(MutationTypes.UPDATE_ELEMENT, { id: handleElement.value.id, props })
      addHistorySnapshot()
    }

    const toggleShadow = (checked: boolean) => {
      if (checked) {
        const props = { shadow: { h: 1, v: 1, blur: 2, color: '#000' } }
        store.commit(MutationTypes.UPDATE_ELEMENT, { id: handleElement.value.id, props })
      }
      else {
        store.commit(MutationTypes.REMOVE_ELEMENT_PROPS, { id: handleElement.value.id, propName: 'shadow' })
      }
      addHistorySnapshot()
    }

    return {
      shadow,
      hasShadow,
      toggleShadow,
      updateShadow,
    }
  },
})
