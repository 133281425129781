
import { computed, defineComponent, PropType } from 'vue'
import { useStore } from '@/store'

import { PPTElement, PPTTextElement } from '@/types/slides'
import { OperateResizeHandler } from '@/types/edit'
import useCommonOperate from '../hooks/useCommonOperate'

import RotateHandler from './RotateHandler.vue'
import ResizeHandler from './ResizeHandler.vue'
import BorderLine from './BorderLine.vue'

export default defineComponent({
	name: 'text-element-operate',
	inheritAttrs: false,
	components: {
		RotateHandler,
		ResizeHandler,
		BorderLine
	},
	props: {
		elementInfo: {
			type: Object as PropType<PPTTextElement>,
			required: true
		},
		isActiveGroupElement: {
			type: Boolean,
			required: true
		},
		isMultiSelect: {
			type: Boolean,
			required: true
		},
		rotateElement: {
			type: Function as PropType<(element: PPTTextElement) => void>,
			required: true
		},
		scaleElement: {
			type: Function as PropType<
				(
					e: MouseEvent,
					element: PPTTextElement,
					command: OperateResizeHandler
				) => void
			>,
			required: true
		},
		selectElement: {
			type: Function as PropType<
				(e: MouseEvent, element: PPTElement, canMove?: boolean) => void
			>,
			required: true
		}
	},
	setup(props) {
		const store = useStore()
		const canvasScale = computed(() => store.state.canvasScale)

		const scaleWidth = computed(
			() => props.elementInfo.width * canvasScale.value
		)
		const scaleHeight = computed(
			() => props.elementInfo.height * canvasScale.value
		)

		const {
			textElementResizeHandlers,
			verticalTextElementResizeHandlers,
			borderLines
		} = useCommonOperate(scaleWidth, scaleHeight)

		const handleSelectElement = (e: MouseEvent, canMove = true) => {
			if (props.elementInfo.lock) return
			e.stopPropagation()

			props.selectElement(e, props.elementInfo, canMove)
		}
		const resizeHandlers = computed(() =>
			props.elementInfo.vertical
				? verticalTextElementResizeHandlers.value
				: textElementResizeHandlers.value
		)

		return {
			scaleWidth,
			textElementResizeHandlers,
			resizeHandlers,
			borderLines,
			handleSelectElement
		}
	}
})
