
import { defineComponent } from 'vue'
import { ElementAlignCommand } from '@/types/edit'
import useCombineElement from '@/hooks/useCombineElement'
import useAlignActiveElement from '@/hooks/useAlignActiveElement'
import useAlignElementToCanvas from '@/hooks/useAlignElementToCanvas'
import useUniformDisplayElement from '@/hooks/useUniformDisplayElement'

export default defineComponent({
    name: 'multi-position-panel',
    setup() {
        const {
            canCombine,
            combineElements,
            uncombineElements
        } = useCombineElement()
        const { alignActiveElement } = useAlignActiveElement()
        const { alignElementToCanvas } = useAlignElementToCanvas()
        const {
            displayItemCount,
            uniformHorizontalDisplay,
            uniformVerticalDisplay
        } = useUniformDisplayElement()

        // 多选元素对齐，需要先判断当前所选中的元素状态：
        // 如果所选元素为一组组合元素，则将它对齐到画布；
        // 如果所选元素不是组合元素或不止一组元素（即当前为可组合状态），则将这多个（多组）元素相互对齐。
        const alignElement = (command: ElementAlignCommand) => {
            if (canCombine.value) alignActiveElement(command)
            else alignElementToCanvas(command)
        }

        return {
            canCombine,
            displayItemCount,
            combineElements,
            uncombineElements,
            uniformHorizontalDisplay,
            uniformVerticalDisplay,
            alignElement
        }
    }
})
