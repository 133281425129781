import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "line-element-operate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BorderLine = _resolveComponent("BorderLine")!
  const _component_PathHandler = _resolveComponent("PathHandler")!
  const _component_ResizeHandler = _resolveComponent("ResizeHandler")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.elementInfo?.customPath && _ctx.customBorderlines)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.customBorderlines, (line) => {
          return (_openBlock(), _createBlock(_component_BorderLine, {
            class: "operate-border-line",
            key: line.type,
            type: line.type,
            style: _normalizeStyle(line.style)
          }, null, 8, ["type", "style"]))
        }), 128))
      : _createCommentVNode("", true),
    (!_ctx.elementInfo.lock && _ctx.elementInfo?.customPath)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.pathHandlers, (point, index) => {
          return (_openBlock(), _createBlock(_component_PathHandler, {
            class: "operate-path-handler",
            key: index,
            style: _normalizeStyle(point.style),
            onMousedown: _withModifiers(
					($event) => _ctx.dragLinePoint($event, _ctx.elementInfo, point.handler, index)
				, ["stop"])
          }, null, 8, ["style", "onMousedown"]))
        }), 128))
      : _createCommentVNode("", true),
    (!_ctx.elementInfo.lock && (_ctx.isActiveGroupElement || !_ctx.isMultiSelect))
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.resizeHandlers, (point) => {
          return (_openBlock(), _createBlock(_component_ResizeHandler, {
            class: "operate-resize-handler",
            key: point?.handler || point?.direction,
            type: point?.direction,
            style: _normalizeStyle(point.style),
            onMousedown: _withModifiers(
					($event) => _ctx.dragLineElement($event, _ctx.elementInfo, point.handler)
				, ["stop"])
          }, null, 8, ["type", "style", "onMousedown"]))
        }), 128))
      : _createCommentVNode("", true)
  ]))
}