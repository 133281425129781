
import { computed, defineComponent, onMounted, ref, toRaw } from 'vue'
import { useStore } from '@/store'

export default defineComponent({
  props: {
    style: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    handleChange: Function,
  },
  setup(props) {
    const store = useStore()
    const currentSelected = computed(() => props.value)
    const compStyle = computed(() => props.style)

    // 最近常用字体
    const usualFonts = ref<
      {
        label: string
        value: string
      }[]
    >([])

    const availableFonts = computed(() => [...store.state.availableFonts])

    const handleChangeCurrentSelected = (value: string) => {
      console.log(availableFonts.value, 'availableFonts')
      // 存储选中的字体样式
      let font: any = undefined
      // const font = (availableFonts.value as any)?.findLast(
      // 	(_font: any) => _font.value === value
      // )
      availableFonts.value.forEach((_font: any) => {
        if (!font && _font.value === value) {
          font = _font
        }
      })
      if (font) {
        const _usualFonts = [toRaw(font)]
        usualFonts.value.forEach((_font: any) => {
          if (_font.value !== font.value) {
            _usualFonts.push(toRaw(_font))
          }
        })
        usualFonts.value =
          _usualFonts.length > 5 ? _usualFonts.slice(0, 5) : _usualFonts
        localStorage.setItem('fontNames', JSON.stringify(usualFonts.value))
      }

      props?.handleChange && props.handleChange(value)
    }
    onMounted(() => {
      const _usualFonts = JSON.parse(localStorage.getItem('fontNames') || '[]')
      if (_usualFonts && _usualFonts?.length > 0) {
        _usualFonts.forEach((_font: any) => {
          const isExitFont:
            | {
                label: string
                value: string
              }
            | undefined = availableFonts.value.find(
            (font: any) => font.value === _font.value
          )
          isExitFont && usualFonts.value.push(isExitFont)
        })
      }
    })
    return {
      usualFonts,
      compStyle,
      currentSelected,
      availableFonts,
      handleChangeCurrentSelected,
    }
  },
})
