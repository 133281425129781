
import {
	computed,
	defineComponent,
	onMounted,
	onUpdated,
	provide,
	ref,
	watch,
	watchEffect
} from 'vue'
import _ from 'lodash'
import { MutationTypes, useStore } from '@/store'
import { ContextmenuItem } from '@/components/Contextmenu/types'
import { PPTElement, Slide } from '@/types/slides'
import { AlignmentLineProps, CreatingLineElement } from '@/types/edit'
import { removeAllRanges } from '@/utils/selection'
import { KEYS } from '@/configs/hotkey'

import useViewportSize from './hooks/useViewportSize'
import useMouseSelection from './hooks/useMouseSelection'
import useDropImageOrText from './hooks/useDropImageOrText'
import useRotateElement from './hooks/useRotateElement'
import useScaleElement from './hooks/useScaleElement'
import useSelectElement from './hooks/useSelectElement'
import useDragElement from './hooks/useDragElement'
import useDragLineElement from './hooks/useDragLineElement'
import useMoveShapeKeypoint from './hooks/useMoveShapeKeypoint'
import useInsertFromCreateSelection from './hooks/useInsertFromCreateSelection'

import useDeleteElement from '@/hooks/useDeleteElement'
import useCopyAndPasteElement from '@/hooks/useCopyAndPasteElement'
import useSelectAllElement from '@/hooks/useSelectAllElement'
import useScaleCanvas from '@/hooks/useScaleCanvas'
import useScreening from '@/hooks/useScreening'
import useSlideHandler from '@/hooks/useSlideHandler'
import usePreviewImage from '@/hooks/usePreviewImage'

import EditableElement from './EditableElement.vue'
import MouseSelection from './MouseSelection.vue'
import ViewportBackground from './ViewportBackground.vue'
import AlignmentLine from './AlignmentLine.vue'
import ElementCreateSelection from './ElementCreateSelection.vue'
import MultiSelectOperate from './Operate/MultiSelectOperate.vue'
import Operate from './Operate/index.vue'
import LinkDialog from './LinkDialog.vue'
import LinkButtonDialog from '../CanvasTool/LinkButtonDialog.vue'

export default defineComponent({
	name: 'editor-canvas',
	components: {
		EditableElement,
		MouseSelection,
		ViewportBackground,
		AlignmentLine,
		ElementCreateSelection,
		MultiSelectOperate,
		Operate,
		LinkDialog,
		LinkButtonDialog
	},
	setup() {
		const store = useStore()

		const activeElementIdList = computed(() => store.state.activeElementIdList)
		const handleElementId = computed(() => store.state.handleElementId)
		const activeGroupElementId = computed(
			() => store.state.activeGroupElementId
		)
		const editorAreaFocus = computed(() => store.state.editorAreaFocus)
		const ctrlKeyState = computed(() => store.state.ctrlKeyState)
		const ctrlOrShiftKeyActive = computed<boolean>(
			() => store.getters.ctrlOrShiftKeyActive
		)

		const viewportRef = ref<HTMLElement>()
		const alignmentLines = ref<AlignmentLineProps[]>([])

		const linkDialogVisible = ref(false)
		const openLinkDialog = () => (linkDialogVisible.value = true)

		const linkButtonDialogVisible = ref(false)
		const openLinkButtonDialog = () => (linkButtonDialogVisible.value = true)

		watch(handleElementId, () => {
			store.commit(MutationTypes.SET_ACTIVE_GROUP_ELEMENT_ID, '')
		})

		const currentSlide = computed<Slide>(() => store.getters.currentSlide)
		const elementList = ref<PPTElement[]>([])
		const setLocalElementList = () => {
			elementList.value = currentSlide.value
				? JSON.parse(JSON.stringify(currentSlide.value.elements))
				: []
		}
		watchEffect(setLocalElementList)

		const canvasRef = ref<HTMLElement>()
		const canvasScale = computed(() => store.state.canvasScale)
		const { viewportStyles, setViewportPosition } = useViewportSize(canvasRef)
		onUpdated(() => {
			setViewportPosition()
		})
		useDropImageOrText(canvasRef)

		const { mouseSelectionState, updateMouseSelection } = useMouseSelection(
			elementList,
			viewportRef
		)

		const { dragElement } = useDragElement(elementList, alignmentLines)
		const { dragLineElement } = useDragLineElement(elementList)
		const { selectElement } = useSelectElement(elementList, dragElement)
		const { scaleElement, scaleMultiElement } = useScaleElement(
			elementList,
			alignmentLines
		)
		const { rotateElement } = useRotateElement(elementList, viewportRef)
		const { moveShapeKeypoint } = useMoveShapeKeypoint(elementList, canvasScale)

		const { selectAllElement } = useSelectAllElement()
		const { deleteAllElements } = useDeleteElement()
		const { pasteElement } = useCopyAndPasteElement()
		const { enterScreening } = useScreening()
		const { updateSlideIndex } = useSlideHandler()

		// 点击画布的空白区域：清空焦点元素、设置画布焦点、清除文字选区
		const handleClickBlankArea = (e: MouseEvent) => {
			store.commit(MutationTypes.SET_ACTIVE_ELEMENT_ID_LIST, [])
			if (!ctrlOrShiftKeyActive.value) {
				updateMouseSelection(e)
			}
			if (!editorAreaFocus.value) {
				store.commit(MutationTypes.SET_EDITORAREA_FOCUS, true)
			}
			removeAllRanges()
		}

		// 移除画布编辑区域焦点
		const removeEditorAreaFocus = () => {
			if (editorAreaFocus.value) {
				store.commit(MutationTypes.SET_EDITORAREA_FOCUS, false)
			}
		}

		// 滚动鼠标
		const { scaleCanvas } = useScaleCanvas()
		const throttleScaleCanvas = _.throttle(scaleCanvas, 100, {
			leading: true,
			trailing: false
		})
		const throttleUpdateSlideIndex = _.throttle(updateSlideIndex, 300, {
			leading: true,
			trailing: false
		})

		const handleMousewheelCanvas = (e: WheelEvent) => {
			// e.preventDefault() // 为了曲谱在编辑模式下可滚动，所以注释掉

			// 按住Ctrl键时：缩放画布
			if (ctrlKeyState.value) {
				if (e.deltaY > 0) throttleScaleCanvas('-')
				else if (e.deltaY < 0) throttleScaleCanvas('+')
			}

			// 上下翻页
			else {
				const viewportWrapperHeight = viewportWrapper.value?.offsetHeight
				if (e.deltaY > (viewportWrapperHeight || 0) / 4) throttleUpdateSlideIndex(KEYS.DOWN)
				else if (e.deltaY < -(viewportWrapperHeight || 0) / 4) throttleUpdateSlideIndex(KEYS.UP)
			}
		}

		// 开关网格线
		const showGridLines = computed(() => store.state.showGridLines)
		const toggleGridLines = () => {
			store.commit(MutationTypes.SET_GRID_LINES_STATE, !showGridLines.value)
		}

		// 在鼠标绘制的范围插入元素
		const creatingElement = computed(() => store.state.creatingElement)
		const { insertElementFromCreateSelection } = useInsertFromCreateSelection(
			viewportRef
		)

		const contextmenus = (): ContextmenuItem[] => {
			return [
				{
					text: '粘贴',
					subText: 'Ctrl + V',
					handler: pasteElement
				},
				{
					text: '全选',
					subText: 'Ctrl + A',
					handler: selectAllElement
				},
				{
					text: '网格线',
					subText: showGridLines.value ? '√' : '',
					handler: toggleGridLines
				},
				{
					text: '重置当前页',
					handler: deleteAllElements
				},
				{ divider: true },
				{
					text: '从当前页演示',
					subText: 'Ctrl+F',
					handler: () => enterScreening()
				}
			]
		}

		provide('slideScale', canvasScale)

		const viewportWrapper = ref<any>()
		onMounted(() => {
			usePreviewImage(viewportWrapper)
		})
		return {
			elementList,
			activeElementIdList,
			handleElementId,
			activeGroupElementId,
			canvasRef,
			viewportRef,
			viewportStyles,
			canvasScale,
			mouseSelectionState,
			currentSlide,
			creatingElement,
			alignmentLines,
			linkDialogVisible,
			openLinkDialog,
			handleClickBlankArea,
			removeEditorAreaFocus,
			insertElementFromCreateSelection,
			selectElement,
			rotateElement,
			moveShapeKeypoint,
			scaleElement,
			dragLineElement,
			scaleMultiElement,
			handleMousewheelCanvas,
			contextmenus,
			viewportWrapper,
			openLinkButtonDialog,
			linkButtonDialogVisible
		}
	}
})
