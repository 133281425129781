
import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
import { useStore } from '@/store'
import _ from 'lodash'
import { PPTElement } from '@/types/slides'
import { CreatingLineElement } from '@/types/edit'
import { getCurveLinePath } from '@/utils/drawLine'

export default defineComponent({
	name: 'element-create-selection',
	props: {
		containerWidth: Number,
		containerHeight: Number,
		containerLeft: Number,
		containerTop: Number
	},
	setup(props, { emit }) {
		const store = useStore()
		const ctrlOrShiftKeyActive = computed<boolean>(
			() => store.getters.ctrlOrShiftKeyActive
		)
		const creatingElement = computed(() => store.state.creatingElement)
		const handleElement = computed<PPTElement>(
			() => store.getters.handleElement
		)

		const customLineRef = ref()

		const start = ref<[number, number]>()
		const end = ref<[number, number]>()

		const svgPath = ref<string[]>([])
		const svgPoint = ref<string[]>([])

		const curvePoints = ref<{ x: number; y: number }[]>([])

		const selectionRef = ref<HTMLElement>()
		const offset = reactive({
			x: 0,
			y: 0
		})
		onMounted(() => {
			if (!selectionRef.value) return
			const { x, y, width, height } = selectionRef.value.getBoundingClientRect()
			offset.x = x
			offset.y = y
			if (creatingElement?.value?.type === 'animation-path') {
				lineAnimationPathData.value.svgWidth = width
				lineAnimationPathData.value.svgHeight = height
				if (handleElement.value) {
					start.value = [
						(handleElement.value.left + handleElement.value.width / 2) *
							store.state.canvasScale +
							offset.x,
						(handleElement.value.top +
							((handleElement.value as any)?.height || 0) / 2) *
							store.state.canvasScale +
							offset.y
					]
				}
			}
			if (
				creatingElement.value?.type === 'line' &&
				creatingElement.value.data?.isCustom
			) {
				start.value = [x, y]
			}
		})

		// 鼠标拖动创建元素生成位置大小
		// 获取范围的起始位置和终点位置
		const createSelection = (e: MouseEvent) => {
			if (
				creatingElement.value?.type === 'animation-path' ||
				(creatingElement.value as CreatingLineElement)?.data?.isCustom
			) {
				return
			}
			let isMouseDown = true
			const startPageX = e.pageX
			const startPageY = e.pageY

			start.value = [startPageX, startPageY]

			document.onmousemove = (e) => {
				if (!creatingElement.value || !isMouseDown) return

				let currentPageX = e.pageX
				let currentPageY = e.pageY

				// 按住Ctrl键或者Shift键时：
				// 对于非线条元素需要锁定宽高比例，对于线条元素需要锁定水平或垂直方向
				if (ctrlOrShiftKeyActive.value) {
					const moveX = currentPageX - startPageX
					const moveY = currentPageY - startPageY

					// 水平和垂直方向的拖动距离，后面以拖动距离较大的方向为基础计算另一方向的数据
					const absX = Math.abs(moveX)
					const absY = Math.abs(moveY)

					if (creatingElement.value.type === 'shape') {
						// 判断是否为反向拖动：从左上到右下为正向操作，此外所有情况都是反向操作
						const isOpposite =
							(moveY > 0 && moveX < 0) || (moveY < 0 && moveX > 0)

						if (absX > absY) {
							currentPageY = isOpposite
								? startPageY - moveX
								: startPageY + moveX
						} else {
							currentPageX = isOpposite
								? startPageX - moveY
								: startPageX + moveY
						}
					} else if (
						creatingElement.value.type === 'line' ||
						creatingElement.value.type === 'animation-path'
					) {
						if (absX > absY) currentPageY = startPageY
						else currentPageX = startPageX
					}
				}

				end.value = [currentPageX, currentPageY]
				computedLineAnimationPathData(false)
				computedCustomCurveLinePathData(false)
			}

			document.onmouseup = (e) => {
				document.onmousemove = null
				document.onmouseup = null
				isMouseDown = false

				const endPageX = e.pageX
				const endPageY = e.pageY

				const minSize = 30

				end.value = [e.pageX, e.pageY]
				computedLineAnimationPathData(true)
				computedCustomCurveLinePathData(true)

				if (
					creatingElement.value?.type === 'line' &&
					(Math.abs(endPageX - startPageX) >= minSize ||
						Math.abs(endPageY - startPageY) >= minSize)
				) {
					emit('created', {
						start: start.value,
						end: end.value
					})
				} else if (
					creatingElement.value?.type !== 'line' &&
					Math.abs(endPageX - startPageX) >= minSize &&
					Math.abs(endPageY - startPageY) >= minSize
				) {
					emit('created', {
						start: start.value,
						end: end.value
					})
				} else {
					const defaultSize = 200
					const minX = Math.min(endPageX, startPageX)
					const minY = Math.min(endPageY, startPageY)
					const maxX = Math.max(endPageX, startPageX)
					const maxY = Math.max(endPageY, startPageY)
					const offsetX = maxX - minX >= minSize ? maxX - minX : defaultSize
					const offsetY = maxY - minY >= minSize ? maxY - minY : defaultSize
					emit('created', {
						start: [minX, minY],
						end: [minX + offsetX, minY + offsetY]
					})
				}
			}
		}

		// 绘制线条的路径相关数据（仅当绘制元素类型为线条时使用）
		const lineData = computed(() => {
			if (!start.value || !end.value) return null
			if (
				!creatingElement.value ||
				(creatingElement.value.type !== 'line' &&
					creatingElement.value.type !== 'animation-path')
			) {
				return null
			}
			const [_startX, _startY] = start.value
			const [_endX, _endY] = end.value
			const minX = Math.min(_startX, _endX)
			const maxX = Math.max(_startX, _endX)
			const minY = Math.min(_startY, _endY)
			const maxY = Math.max(_startY, _endY)

			const svgWidth = maxX - minX >= 24 ? maxX - minX : 24
			const svgHeight = maxY - minY >= 24 ? maxY - minY : 24

			const startX = _startX === minX ? 0 : maxX - minX
			const startY = _startY === minY ? 0 : maxY - minY
			const endX = _endX === minX ? 0 : maxX - minX
			const endY = _endY === minY ? 0 : maxY - minY

			const path = `M${startX}, ${startY} L${endX}, ${endY}`
			return {
				svgWidth,
				svgHeight,
				startX,
				startY,
				endX,
				endY,
				path
			}
		})

		const lineAnimationPathData = ref<{
			svgWidth: number
			svgHeight: number
			startX: number
			startY: number
			endX: number
			endY: number
			path: string
		}>({
			svgWidth: 0,
			svgHeight: 0,
			startX: 0,
			startY: 0,
			endX: 0,
			endY: 0,
			path: ''
		})

		const curveLinePathData = ref<{
			startX: number
			startY: number
			endX: number
			endY: number
			path: string
			svgWidth: number
			svgHeight: number
		}>({
			startX: 0,
			startY: 0,
			endX: 0,
			endY: 0,
			path: '',
			svgWidth: props.containerWidth || 0,
			svgHeight: props.containerHeight || 0
		})

		const computedCustomCurveLinePathData = (isMouseDown = false) => {
			if (!start.value || !end.value) return null
			if (!creatingElement.value || creatingElement.value.type !== 'line') {
				return null
			}
			const [_startX, _startY] = start.value
			const [_endX, _endY] = end.value

			if (curvePoints.value.length > 0) {
				if (isMouseDown) {
					curvePoints.value.push({ x: _endX - _startX, y: _endY - _startY })
				} else {
					curvePoints.value[curvePoints.value.length - 1] = {
						x: _endX - _startX,
						y: _endY - _startY
					}
				}
			} else {
				curvePoints.value[0] = { x: _endX - _startX, y: _endY - _startY }
			}
			if (curvePoints.value.length === 1) {
				return
			}
			if (!isMouseDown) {
				const path = getCurveLinePath(curvePoints.value, isMouseDown)
			_.merge(curveLinePathData.value, {
				path
			})
			}
		}

		// 计算动画路径
		const computedLineAnimationPathData = (isMouseDown = false) => {
			if (!start.value || !end.value) return null
			if (
				!creatingElement.value ||
				creatingElement.value.type !== 'animation-path'
			) {
				return null
			}
			const [_startX, _startY] = start.value
			const [_endX, _endY] = end.value

			if (svgPoint.value.length > 0) {
				if (isMouseDown) {
					svgPoint.value.push(`${_endX}, ${_endY}`)
				} else {
					svgPoint.value[svgPoint.value.length - 1] = `${_endX}, ${_endY}`
				}
			} else {
				svgPoint.value.push(`${_startX}, ${_startY}`)
				svgPoint.value.push(`${_endX}, ${_endY}`)
			}

			const pathX: number[] = []
			const pathY: number[] = []
			svgPoint.value.forEach((point: string, index: number) => {
				if (index === 0) {
					pathX.push(+point.split(',')[0])
				} else {
					pathX.push(+point.split(',')[0])
				}
				pathY.push(+point.split(',')[1])
			})
			const minX = Math.min(...pathX) || 0
			const maxX = Math.max(...pathX) || 0
			const minY = Math.min(...pathY) || 0
			const maxY = Math.max(...pathY) || 0

			svgPath.value.length = 0
			svgPoint.value.forEach((point: string, index: number) => {
				const [x, y] = point.split(',')

				const _x = +x - minX
				const _y = +y - minY
				if (index === 0) {
					svgPath.value.push(`M${_x},${_y} `)
				} else {
					svgPath.value.push(`L${_x},${_y} `)
				}
			})

			const svgWidth = maxX - minX >= 24 ? maxX - minX : 24
			const svgHeight = maxY - minY >= 24 ? maxY - minY : 24

			const path = svgPath.value.join('')

			_.merge(lineAnimationPathData.value, {
				path,
				svgHeight,
				svgWidth
			})
		}
		// 根据生成范围的起始位置和终点位置，计算元素创建时的位置和大小
		const position = computed(() => {
			if (!start.value || !end.value) return {}

			const [startX, startY] = start.value
			const [endX, endY] = end.value
			const minX = Math.min(startX, endX)
			const maxX = Math.max(startX, endX)
			const minY = Math.min(startY, endY)
			const maxY = Math.max(startY, endY)

			const width = maxX - minX
			const height = maxY - minY

			// fixme: 要兼顾屏幕滚动window.scrollX；window.scrollY
			return {
				left: minX - offset.x - (window.scrollX || 0) + 'px',
				top: minY - offset.y - (window.scrollY || 0) + 'px',
				width: width + 'px',
				height: height + 'px'
			}
		})

		const customPosition = computed(() => {
			return {
				left: 0,
				top: 0,
				width: props.containerWidth + 'px',
				height: props.containerHeight + 'px'
			}
		})

		// 动画路径位置、宽高
		const animationPosition = computed(() => {
			if (svgPoint.value.length > 0) {
				const pathX: number[] = []
				const pathY: number[] = []
				svgPoint.value.forEach((point: string, index: number) => {
					if (index === 0) {
						pathX.push(+point.split(',')[0])
					} else {
						pathX.push(+point.split(',')[0])
					}
					pathY.push(+point.split(',')[1])
				})
				const minX = Math.min(...pathX) || 0
				const maxX = Math.max(...pathX) || 0
				const minY = Math.min(...pathY) || 0
				const maxY = Math.max(...pathY) || 0

				const width = maxX - minX
				const height = maxY - minY
				return {
					left: minX - offset.x + 'px',
					top: minY - offset.y + 'px',
					width: width + 'px',
					height: height + 'px'
				}
			}
			return {}
		})

		const moveSelection = (e: MouseEvent) => {
			if (
				creatingElement.value?.type === 'animation-path' ||
				(creatingElement.value?.type === 'line' &&
					(creatingElement.value as CreatingLineElement)?.data?.isCustom)
			) {
				const startPageX = e.pageX - window.scrollX
				const startPageY = e.pageY - window.scrollY

				end.value = [startPageX, startPageY]
				computedLineAnimationPathData(false)
				computedCustomCurveLinePathData(false)
			}
		}

		const createSelectionClick = (e: MouseEvent) => {
			end.value = [e.pageX, e.pageY]
			if (creatingElement.value?.type === 'animation-path') {
				end.value = [e.pageX - window.scrollX, e.pageY - window.scrollY]
				computedLineAnimationPathData(true)
			} else if (
				creatingElement.value?.type === 'line' &&
				(creatingElement.value as CreatingLineElement)?.data?.isCustom
			) {
				end.value = [e.pageX - window.scrollX, e.pageY - window.scrollY]
				computedCustomCurveLinePathData(true)
			}
		}

		const handleClickRight = (e: MouseEvent) => {
			if (creatingElement.value?.type === 'animation-path') {
				end.value = [e.pageX - window.scrollX, e.pageY - window.scrollY]
				computedLineAnimationPathData(true)

				e.preventDefault()
				e.stopPropagation()
				const _svgPoint: any[] = []
				let originPoint: number[]

				const pathX: number[] = []
				const pathY: number[] = []

				let totalPathLength = 0

				svgPoint.value.forEach((point: string, index: number) => {
					const _point = point.split(',')
					const x = +_point[0] - offset.x
					const y = +_point[1] - offset.y
					if (index === 0) {
						originPoint = [x, y]
						_svgPoint.push([0, 0])
						pathX.push(0)
						pathY.push(0)
					} else {
						const _x = (x - originPoint[0]) / store.state.canvasScale
						const _y = (y - originPoint[1]) / store.state.canvasScale
						pathX.push(_x)
						pathY.push(_y)
						const length = Math.sqrt(
							Math.pow(_x - pathX[index - 1], 2) +
								Math.pow(_y - pathY[index - 1], 2)
						)
						totalPathLength += length
						_svgPoint.push([_x, _y, length])
					}
				})
				const _svgPath: string[] = []

				_svgPoint.forEach((point: number[], index: number) => {
					const [x, y] = point
					if (index === 0) {
						_svgPath.push(`M${x},${y} `)
					} else {
						_svgPath.push(`L${x},${y} `)
						point[2] = point[2] / totalPathLength
					}
				})

				const minX = Math.min(...pathX) || 0
				const maxX = Math.max(...pathX) || 0
				const minY = Math.min(...pathY) || 0
				const maxY = Math.max(...pathY) || 0
				const width = (maxX - minX) / store.state.canvasScale
				const height = (maxY - minY) / store.state.canvasScale

				emit('created', {
					start: start.value,
					end: end.value,
					path: {
						path: _svgPath.join(''),
						points: _svgPoint,
						width: width,
						height: height
					}
				})
			} else if (
				creatingElement.value?.type === 'line' &&
				(creatingElement.value as CreatingLineElement)?.data?.isCustom
			) {
				end.value = [e.pageX - window.scrollX, e.pageY - window.scrollY]
				computedCustomCurveLinePathData(true)

				e.preventDefault()
				e.stopPropagation()

				const bbox = customLineRef.value?.$el?.getBBox() || { x: 0, y: 0, width: 0, height: 0 }

				const width = bbox.width / store.state.canvasScale
				const height = bbox.height / store.state.canvasScale
				const _svgPoint = curvePoints.value.map((point: { x: number; y: number }) => {
						return {
							x: (point.x - bbox.x) / store.state.canvasScale,
							y: ( point.y - bbox.y) / store.state.canvasScale
						}
				})
				_svgPoint.pop()

				const path = getCurveLinePath(
					_svgPoint
				)

				emit('created', {
					start: [bbox.x / store.state.canvasScale, bbox.y / store.state.canvasScale],
					end: [(bbox.x + bbox.width) / store.state.canvasScale, (bbox.y + bbox.height) / store.state.canvasScale],
					path: {
						path: path,
						points: _.cloneDeep(_svgPoint),
						width: width,
						height: height
					}
				})
			}
		}
		return {
			selectionRef,
			start,
			end,
			creatingElement,
			createSelection,
			lineAnimationPathData,
			lineData,
			position,
			moveSelection,
			animationPosition,
			createSelectionClick,
			handleClickRight,
			customPosition,
			curveLinePathData,
			customLineRef
		}
	}
})
