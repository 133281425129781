import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconFontSize = _resolveComponent("IconFontSize")!
  const _component_SelectOption = _resolveComponent("SelectOption")!
  const _component_SelectOptGroup = _resolveComponent("SelectOptGroup")!
  const _component_Select = _resolveComponent("Select")!

  return (_openBlock(), _createBlock(_component_Select, {
    class: "font-name-select",
    style: _normalizeStyle(_ctx.compStyle),
    value: _ctx.currentSelected,
    onChange: _cache[0] || (_cache[0] = (value) => _ctx.handleChangeCurrentSelected(value)),
    dropdownClassName: "text-font-name-select_dropdown"
  }, {
    suffixIcon: _withCtx(() => [
      _createVNode(_component_IconFontSize)
    ]),
    default: _withCtx(() => [
      (_ctx.usualFonts.length > 0)
        ? (_openBlock(), _createBlock(_component_SelectOptGroup, {
            key: 0,
            label: "最近使用字体"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.usualFonts, (font) => {
                return (_openBlock(), _createBlock(_component_SelectOption, {
                  key: font.value + 'usual',
                  value: font.value
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", {
                      style: _normalizeStyle({
            fontFamily: font.fontFamily,
          })
                    }, _toDisplayString(font.label), 5)
                  ]),
                  _: 2
                }, 1032, ["value"]))
              }), 128))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_SelectOptGroup, { label: "所有字体" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableFonts, (font) => {
            return (_openBlock(), _createBlock(_component_SelectOption, {
              key: font.value + 'all',
              value: font.value
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  style: _normalizeStyle({
            fontFamily: font.fontFamily,
          })
                }, _toDisplayString(font.label), 5)
              ]),
              _: 2
            }, 1032, ["value"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["style", "value"]))
}