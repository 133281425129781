import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-91467b6a")
const _hoisted_1 = { class: "hotkey-doc" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "label" }
const _hoisted_4 = { class: "value" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hotkeys, (item) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: item.type
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(item.type), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (hotkey) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "hotkey-item",
            key: hotkey.label
          }, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(hotkey.label), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(hotkey.value), 1)
          ]))
        }), 128))
      ], 64))
    }), 128))
  ]))
}