import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "alignment-line",
    style: _normalizeStyle({ left: _ctx.left, top: _ctx.top })
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(['line', _ctx.type]),
      style: _normalizeStyle(_ctx.sizeStyle)
    }, null, 6)
  ], 4))
}