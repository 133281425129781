import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-ff676148")
const _hoisted_1 = ["id"]
const _hoisted_2 = ["id"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ThumbnailElement = _resolveComponent("ThumbnailElement")!

  return (_openBlock(), _createElementBlock("div", {
    id: `modify-record-thumbnail-slide-${_ctx.slide.id}`,
    ref: "thumbnailSlideRef",
    style: _normalizeStyle({
			width: _ctx.size + 'px',
			height: _ctx.size * _ctx.viewportRatio + 'px',
			backgroundImage: _ctx.slide?.preview ? `url('${_ctx.slide?.preview}')` : 'none'
		}),
    class: "modify-record-thumbnail-slide"
  }, [
    (!_ctx.slide?.preview)
      ? (_openBlock(), _createElementBlock("div", {
          id: `thumbnailSlide-${_ctx.slide.id}`,
          key: `${_ctx.slide.id}-render`,
          style: _normalizeStyle({
				width: _ctx.VIEWPORT_SIZE + 'px',
				height: _ctx.VIEWPORT_SIZE * _ctx.viewportRatio + 'px',
				transform: `scale(${_ctx.scale})`
			}),
          class: "elements"
        }, [
          _createElementVNode("div", {
            style: _normalizeStyle(_ctx.backgroundStyle),
            class: "background"
          }, null, 4),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slide.elements, (element, index) => {
            return (_openBlock(), _createBlock(_component_ThumbnailElement, {
              key: element.id,
              elementIndex: index + 1,
              elementInfo: element,
              "preview-sign": true
            }, null, 8, ["elementIndex", "elementInfo"]))
          }), 128))
        ], 12, _hoisted_2))
      : _createCommentVNode("", true)
  ], 12, _hoisted_1))
}