import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, withModifiers as _withModifiers, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-9e6848e2")
const _hoisted_1 = ["d"]
const _hoisted_2 = ["d"]
const _hoisted_3 = ["d"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgWrapper = _resolveComponent("SvgWrapper")!

  return (_openBlock(), _createElementBlock("div", {
    class: "element-create-selection",
    ref: "selectionRef",
    onMousedown: _cache[0] || (_cache[0] = _withModifiers(($event) => _ctx.createSelection($event), ["stop"])),
    onMousemove: _cache[1] || (_cache[1] = ($event) => _ctx.moveSelection($event)),
    onClick: _cache[2] || (_cache[2] = _withModifiers(($event) => _ctx.createSelectionClick($event), ["left"])),
    onContextmenu: _cache[3] || (_cache[3] = _withModifiers(($event) => _ctx.handleClickRight($event), ["right"]))
  }, [
    (_ctx.start && _ctx.end)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(['selection', _ctx.creatingElement?.type]),
          style: _normalizeStyle(
				_ctx.creatingElement?.type === 'animation-path'
					? _ctx.animationPosition
					: (_ctx.creatingElement as CreatingLineElement)?.data?.isCustom ? _ctx.customPosition: _ctx.position
			)
        }, [
          (
					_ctx.creatingElement?.type === 'line' &&
						_ctx.lineData &&
						!_ctx.creatingElement?.data?.isCustom
				)
            ? (_openBlock(), _createBlock(_component_SvgWrapper, {
                key: 0,
                overflow: "visible",
                width: _ctx.lineData.svgWidth,
                height: _ctx.lineData.svgHeight
              }, {
                default: _withCtx(() => [
                  _createElementVNode("path", {
                    d: _ctx.lineData.path,
                    stroke: "#d14424",
                    fill: "none",
                    "stroke-width": "1",
                    "stroke-linecap": "",
                    "stroke-linejoin": "",
                    "stroke-miterlimit": ""
                  }, null, 8, _hoisted_1)
                ]),
                _: 1
              }, 8, ["width", "height"]))
            : _createCommentVNode("", true),
          (
					_ctx.creatingElement?.type === 'line' &&
						_ctx.lineData &&
						_ctx.creatingElement?.data?.isCustom
				)
            ? (_openBlock(), _createBlock(_component_SvgWrapper, {
                key: 1,
                overflow: "visible",
                width: _ctx.curveLinePathData.svgWidth,
                height: _ctx.curveLinePathData.svgHeight,
                ref: "customLineRef"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("path", {
                    d: _ctx.curveLinePathData.path,
                    stroke: "#d14424",
                    fill: "none",
                    "stroke-width": "1",
                    "stroke-linecap": "",
                    "stroke-linejoin": "",
                    "stroke-miterlimit": ""
                  }, null, 8, _hoisted_2)
                ]),
                _: 1
              }, 8, ["width", "height"]))
            : _createCommentVNode("", true),
          (
					_ctx.creatingElement?.type === 'animation-path' &&
						_ctx.lineAnimationPathData.path
				)
            ? (_openBlock(), _createBlock(_component_SvgWrapper, {
                key: 2,
                overflow: "visible",
                left: 0,
                top: 0,
                width: _ctx.lineAnimationPathData.svgWidth,
                height: _ctx.lineAnimationPathData.svgHeight
              }, {
                default: _withCtx(() => [
                  _createElementVNode("path", {
                    d: _ctx.lineAnimationPathData.path,
                    stroke: "#d14424",
                    fill: "none",
                    "stroke-width": "1",
                    "stroke-linecap": "",
                    "stroke-linejoin": "",
                    "stroke-miterlimit": ""
                  }, null, 8, _hoisted_3)
                ]),
                _: 1
              }, 8, ["width", "height"]))
            : _createCommentVNode("", true)
        ], 6))
      : _createCommentVNode("", true)
  ], 544))
}