
export default {
  name: 'mouse-selection',
  props: {
    top: {
      type: Number,
      required: true,
    },
    left: {
      type: Number,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    quadrant: {
      type: Number,
      required: true,
      validator(value: number) {
        return [1, 2, 3, 4].includes(value)
      },
    },
  }
}
