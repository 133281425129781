import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`mouse-selection quadrant-${$props.quadrant}`),
    style: _normalizeStyle({
      top: $props.top + 'px',
      left: $props.left + 'px',
      width: $props.width + 'px',
      height: $props.height + 'px',
    })
  }, null, 6))
}