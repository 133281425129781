
import { computed, defineComponent, PropType } from 'vue'
import { useStore } from '@/store'

import { PPTTableElement } from '@/types/slides'
import { OperateResizeHandler } from '@/types/edit'
import useCommonOperate from '../hooks/useCommonOperate'

import ResizeHandler from './ResizeHandler.vue'
import BorderLine from './BorderLine.vue'

export default defineComponent({
  name: 'table-element-operate',
  inheritAttrs: false,
  components: {
    ResizeHandler,
    BorderLine,
  },
  props: {
    elementInfo: {
      type: Object as PropType<PPTTableElement>,
      required: true,
    },
    isActiveGroupElement: {
      type: Boolean,
      required: true,
    },
    isMultiSelect: {
      type: Boolean,
      required: true,
    },
    scaleElement: {
      type: Function as PropType<(e: MouseEvent, element: PPTTableElement, command: OperateResizeHandler) => void>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()
    const canvasScale = computed(() => store.state.canvasScale)

    const outlineWidth = computed(() => props.elementInfo.outline.width || 1)

    const scaleWidth = computed(() => (props.elementInfo.width + outlineWidth.value) * canvasScale.value)
    const scaleHeight = computed(() => props.elementInfo.height * canvasScale.value)

    const { textElementResizeHandlers, borderLines } = useCommonOperate(scaleWidth, scaleHeight)

    return {
      scaleWidth,
      textElementResizeHandlers,
      borderLines,
    }
  },
})
