
import { computed, defineComponent, onMounted, ref } from 'vue'
import { MutationTypes, useStore } from '@/store'
import { PPTElement, PPTElementLink } from '@/types/slides'
import useLink from '@/hooks/useLink'
import useCreateElement from '@/hooks/useCreateElement'

export default defineComponent({
	name: 'link-dialog',
	setup(props, { emit }) {
		const store = useStore()
		const handleElement = computed<PPTElement | null>(
			() => store.getters.handleElement
		)
		const isCreatingLinkButton = computed(() => store.state.creatingElement)
		const link = ref('')
		const linktitle = ref('')

		const { checkLink, setLink } = useLink()

		onMounted(() => {
			if (isCreatingLinkButton.value) {
				link.value = ''
				linktitle.value = ''
			} else {
				if (
					handleElement.value?.link &&
					handleElement.value?.link.type === 'web-url'
				) {
					link.value = handleElement.value.link.target
				}
				if (
					handleElement.value?.type === 'link-button' &&
					handleElement.value.title
				) {
					linktitle.value = handleElement.value.title
				}
			}
		})

		const close = () => {
			store.commit(MutationTypes.SET_CREATING_ELEMENT, null)
			emit('close')
		}

		const { createLinkButtonElement } = useCreateElement()

		const save = () => {
			if (
				handleElement.value &&
				handleElement.value.type === 'link-button' &&
				!isCreatingLinkButton.value
			) {
				const success = setLink(handleElement.value, link.value)
				if (success) {
					const props = {title: linktitle.value}
					store.commit(MutationTypes.UPDATE_ELEMENT, {
						id: handleElement.value.id,
						props
					})
					close()
				}
				else link.value = ''
			} else if (checkLink(link.value)) {
				const newLinkButton: PPTElementLink = {
					type: 'web-url',
					target: link.value
				}
				if (linktitle.value) createLinkButtonElement(newLinkButton, linktitle.value)
				else createLinkButtonElement(newLinkButton)
				close()
			}
		}

		return {
			link,
			close,
			save,
			linktitle
		}
	}
})
