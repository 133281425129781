
import { defineComponent } from 'vue'
import { HOTKEY_DOC } from '@/configs/hotkey'

const hotkeys = HOTKEY_DOC

export default defineComponent({
  name: 'hotkey-doc',
  setup() {
    return {
      hotkeys,
    }
  },
})
