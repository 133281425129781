import { computed, Ref } from 'vue'
import { MutationTypes, useStore } from '@/store'
import {
	CreateElementSelectionData,
	CreatingLineElement,
	CreatingShapeElement,
	CreatingTextElement
} from '@/types/edit'
import useCreateElement from '@/hooks/useCreateElement'
import {
	ANIMATION_DEFAULT_AUDIO,
	ANIMATION_DEFAULT_DELAY,
	ANIMATION_DEFAULT_DURATION,
	ANIMATION_DEFAULT_RUN_TIMES,
	ANIMATION_DEFAULT_TRIGGER
} from '@/configs/animation'
import { v4 as uuidv4 } from 'uuid'
import {
	PPTAnimation,
	PPTAnimationGroup,
	PPTAnimationTrigger
} from '@/types/slides'
import { runCustomAnimation } from '@/utils/animation'
import _ from 'lodash'

export default (viewportRef: Ref<HTMLElement | undefined>) => {
	const store = useStore()
	const canvasScale = computed(() => store.state.canvasScale)
	const creatingElement = computed(() => store.state.creatingElement)

	// 通过鼠标框选时的起点和终点，计算选区的位置大小
	const formatCreateSelection = (selectionData: CreateElementSelectionData) => {
		const { start, end } = selectionData

		if (!viewportRef.value) return
		const viewportRect = viewportRef.value.getBoundingClientRect()

		const [startX, startY] = start
		const [endX, endY] = end
		const minX = Math.min(startX, endX)
		const maxX = Math.max(startX, endX)
		const minY = Math.min(startY, endY)
		const maxY = Math.max(startY, endY)

		// fixme:要兼顾屏幕滚动window.scrollX；window.scrollY
		const left =
			(minX - viewportRect.x - window.scrollX || 0) / canvasScale.value
		const top =
			(minY - viewportRect.y - window.scrollY || 0) / canvasScale.value
		const width = (maxX - minX) / canvasScale.value
		const height = (maxY - minY) / canvasScale.value

		return { left, top, width, height }
	}

	// 通过鼠标框选时的起点和终点，计算线条在画布中的位置和起点终点
	const formatCreateSelectionForLine = (
		selectionData: CreateElementSelectionData
	) => {
		const { start, end } = selectionData

		if (!viewportRef.value) return
		const viewportRect = viewportRef.value.getBoundingClientRect()

		const [startX, startY] = start
		const [endX, endY] = end
		const minX = Math.min(startX, endX)
		const maxX = Math.max(startX, endX)
		const minY = Math.min(startY, endY)
		const maxY = Math.max(startY, endY)

		// fixme:要兼顾屏幕滚动window.scrollX；window.scrollY
		const left =
			(minX - viewportRect.x - window.scrollX || 0) / canvasScale.value
		const top =
			(minY - viewportRect.y - window.scrollY || 0) / canvasScale.value
		const width = (maxX - minX) / canvasScale.value
		const height = (maxY - minY) / canvasScale.value

		const _start: [number, number] = [
			startX === minX ? 0 : width,
			startY === minY ? 0 : height
		]
		const _end: [number, number] = [
			endX === minX ? 0 : width,
			endY === minY ? 0 : height
		]

		return {
			left,
			top,
			start: _start,
			end: _end
		}
	}

	const {
		createTextElement,
		createShapeElement,
		createLineElement
	} = useCreateElement()


	// 根据鼠标选区的位置大小插入元素
	const insertElementFromCreateSelection = (
		selectionData: CreateElementSelectionData
	) => {
		if (!creatingElement.value) return

		const type = creatingElement.value.type

		if (type === 'animation-path') {
			const animations = store.getters.currentSlideAnimations
			let lastNextPateAnimationIndex = animations.findIndex(
				(animation: PPTAnimation) => animation?.triggerSource
			)
			if (lastNextPateAnimationIndex < 0) {
				lastNextPateAnimationIndex = animations?.length || 0
			}
			const newAnimation = {
				id: uuidv4(),
				elId: store.state.handleElementId,
				type: 'custom',
				group: 'attention' as PPTAnimationGroup,
				duration: ANIMATION_DEFAULT_DURATION,
				delay: ANIMATION_DEFAULT_DELAY,
				runTimes: ANIMATION_DEFAULT_RUN_TIMES,
				trigger: ANIMATION_DEFAULT_TRIGGER as PPTAnimationTrigger,
				audio: ANIMATION_DEFAULT_AUDIO,
				path: selectionData?.path
			}
			animations.splice(lastNextPateAnimationIndex, 0, newAnimation)
			store.commit(MutationTypes.UPDATE_SLIDE, {
				animations
			})
			setTimeout(() => {
				const elRef = document.querySelector(
					`#editable-element-${store.state.handleElementId} [class^=editable-element-]`
				)
				elRef && runCustomAnimation(elRef as HTMLElement, newAnimation, false)
			}, 60)
		}
		if (type === 'text') {
			const position = formatCreateSelection(selectionData)
			position &&
				createTextElement(position, {
					content: (creatingElement.value as CreatingTextElement).content || '',
					vertical: (creatingElement.value as CreatingTextElement).vertical
				})
		} else if (type === 'shape') {
			const position = formatCreateSelection(selectionData)
			position &&
				createShapeElement(
					position,
					(creatingElement.value as CreatingShapeElement).data
				)
		} else if (type === 'line') {
			if ((creatingElement.value as CreatingLineElement).data?.isCustom) {
				createLineElement(
					{start: [0, 0], end: [selectionData?.path?.width || 0, selectionData?.path?.height || 0], left: selectionData?.start[0] || 0, top: selectionData?.start?.[1] || 0},
					_.merge((creatingElement.value as CreatingLineElement).data, {customPath: selectionData?.path?.path, custom: selectionData?.path?.points})
				)

				return
			}
				const position = formatCreateSelectionForLine(selectionData)
				position &&
					createLineElement(
						position,
						(creatingElement.value as CreatingLineElement).data
				)
		}
		store.commit(MutationTypes.SET_CREATING_ELEMENT, null)
	}

	return {
		insertElementFromCreateSelection
	}
}
