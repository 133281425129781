
import {
	computed,
	defineComponent,
	onMounted,
	ref,
	onBeforeUnmount,
	watch
} from 'vue'

import useGlobalHotkey from '@/hooks/useGlobalHotkey'
import usePasteEvent from '@/hooks/usePasteEvent'
import useSavePpt from '@/hooks/useSavePpt'
import useModifyRecord from '@/hooks/useModifyRecord'
import { useStore } from '@/store'

import EditorHeader from './EditorHeader/index.vue'
import Canvas from './Canvas/index.vue'
import CanvasTool from './CanvasTool/index.vue'
import Thumbnails from './Thumbnails/index.vue'
import Toolbar from './Toolbar/index.vue'
import { PptState } from '@/types/pptState'
import _ from 'lodash'
// import Remark from './Remark/index.vue'

export default defineComponent({
	name: 'editor',
	components: {
		EditorHeader,
		Canvas,
		CanvasTool,
		Thumbnails,
		Toolbar
	},
	setup() {
		const remarkHeight = ref(0)
		const { checkIsAutoSaving, checkAutoSaveAbort } = useSavePpt()
		const {
			initModifyRecords,
			startIntervalSaveModify
		} = useModifyRecord()

		useGlobalHotkey()
		usePasteEvent()
		const store = useStore()
		const knowledgePointsShow = computed(
			() => store.state.editMode === 'knowledgePoints'
		)
		const isAutoSaving = computed(() => checkIsAutoSaving.value)
		const isAutoSavingAbort = computed(() => checkAutoSaveAbort.value)

		const hiddenToolBar = ref(false)
		const changeHiddenToolBar = (state?: boolean) => {
			if (state !== undefined) {
				hiddenToolBar.value = state
			} else {
				hiddenToolBar.value = !hiddenToolBar.value
			}
		}
		// 监听ppt的变化，切换ppt时重置自动保存定时器
		watch(
			() => store.state.uuid,
			_.debounce(
				(newVal, oldVal) => {
					if (newVal !== '' && newVal !== oldVal) {
						initModifyRecords()
					}
				},
				200,
				{ leading: false, trailing: true }
			)
		)
		watch(
			() => store.state.pptState,
			(newVal) => {
				if (newVal === PptState.MODIFY) {
					startIntervalSaveModify()
				}
			}
		)
		onMounted(() => {
			// 5分钟后自动保存
			// startAutoSavePpt(300000)
			// initModifyRecords()
		})
		onBeforeUnmount(() => {
			// closeAutoSavePpt()
		})

		return {
			isAutoSaving,
			isAutoSavingAbort,
			remarkHeight,
			knowledgePointsShow,
			hiddenToolBar,
			changeHiddenToolBar
		}
	}
})
