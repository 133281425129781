
import {
    computed,
    defineComponent,
    createVNode,
    ref,
    getCurrentInstance,
    ComponentInternalInstance,
    watch
} from 'vue'
import { message, Modal, Badge } from 'ant-design-vue'
import HotkeyDoc from './HotkeyDoc.vue'
import useExport from '@/hooks/useExport'
import useImport from '@/hooks/useImport'
import usePageLoading from '@/hooks/usePageLoading'
import useShowMessage from '@/hooks/useShowMessage'
import useFullScreen from '@/hooks/useFullScreen'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'
import useQianKun from '@/hooks/useQianKun'
import useRunTime from '@/hooks/useRunTime'
import useSlideHandler from '@/hooks/useSlideHandler'
import { MutationTypes, useStore } from '@/store'
import logger from '@evideo/logger'
import usePreviewImage from '@/hooks/usePreviewImage'
import useQianKunEventCenter from '@/hooks/useQianKunEventCenter'
import useModifyRecord from '@/hooks/useModifyRecord'
import emitter, { EmitterEvents } from '@/utils/emitter'

import ModifyRecordsModal from '../ModifyRecordsModal/index.vue'

export default defineComponent({
    name: 'editor-header',
    components: {
        Badge,
        HotkeyDoc,
        ModifyRecordsModal
    },
    props: {
        isAutoSaving: {
            type: Boolean,
            default: false
        },
        isAutoSavingAbort: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        const store = useStore()
        const { pptGeneratePreviewOneByOne } = usePreviewImage()
        const { runtime, isDevelopment, setUploadMode } = useRunTime()
        const { isMicroApp } = useQianKun()
        const { saveToCloud, saveKnowledgePoints, exportJSON } = useExport()
        const { importJson, importPptx } = useImport()
        const {
            showLoading,
            changeLoadingText,
            closeLoading
        } = usePageLoading()
        const { showMeassage } = useShowMessage()
        const { noticeGoBack } = useQianKunEventCenter()
        const { compareModifyDiff } = useModifyRecord()

        const { createSlide, deleteSlide } = useSlideHandler()
        const {
            redo,
            undo,
            addHistorySnapshot,
            deleteLastHistorySnapshot
        } = useHistorySnapshot()

        const { switchFullScreen } = useFullScreen()

        const knowledgePointsShow = computed(
            () => store.state.editMode === 'knowledgePoints'
        )

        const editorHeaderToolLeft = ref()
        const editorHeaderToolRight = ref()

        const pptNameStyle = computed(() => {
            if (!editorHeaderToolLeft.value || !editorHeaderToolRight.value) {
                return {}
            }
            const leftToolRect = (editorHeaderToolLeft.value as Element)?.getBoundingClientRect()
            // const rightWidth = (editorHeaderToolRight.value as Element)?.getBoundingClientRect()?.width || 0
            return {
                marginLeft: `${(leftToolRect?.width || 0) + 30}px`,
                width: `calc(100% - ${2 * (leftToolRect?.width || 0) + 60}px)`
            }
        })

        const modifyRecordsModalVisible = ref(false)
        const showInitModifyDiffDot = computed(() => compareModifyDiff.value)

        const showGridLines = computed(() => store.state.showGridLines)
        const pptName = computed(() => runtime?.extData?.pptName)

        const showDocument = computed(() => runtime?.isShowDocument)

        const toggleGridLines = () => {
            store.commit(
                MutationTypes.SET_GRID_LINES_STATE,
                !showGridLines.value
            )
        }

        const hotkeyDrawerVisible = ref(false)

        const handelSetUploadMode = () => {
            const mode = runtime.uploadMode === 'local' ? 'cloud' : 'local'
            setUploadMode(mode)
            message.success(`上传模式切换为:${runtime.uploadMode}`)
        }

        const savePreviewImage = () => {
            return new Promise<void>((reslove, reject) => {
                logger.time('(ppt文件导入)-预览图生成时间')
                setTimeout(() => {
                    changeLoadingText('生成预览图中，请勿关闭窗口...')
                    pptGeneratePreviewOneByOne(store)
                        .then(() => {
                            logger.timeEnd('(ppt文件导入)-预览图生成时间')
                        })
                        .catch((e) => {
                            logger.error('(ppt文件导入)-预览图生成失败', e)
                            reject(e)
                        })
                        .finally(() => {
                            reslove()
                        })
                }, 0)
            })
        }
        const createImportFileHandler = () => {
            return new Promise<void>((resolve) => {
                const fileInput = document.createElement('input')
                fileInput.type = 'file'
                fileInput.accept = '.pptx,application/json'
                const fileValue = ref('')
                fileInput.onchange = (e: any) => {
                    fileValue.value = e
                    const file = e.target.files[0]
                    logger.info('importFile', file)
                    // 文件名称中可能出现 .
                    const extension = file.name.split('.').reverse()?.[0]
                    showLoading('导入中,请勿关闭窗口...', 0)
                    if (extension === 'pptx') {
                        logger.time('(ppt文件导入)-解析总时间')
                        importPptx(file)
                            .then((jsonStr) => {
                                logger.timeEnd('(ppt文件导入)-解析总时间')
                                importJson(jsonStr || '{}')
                                    .then(() => {
                                        savePreviewImage()
                                            .then(() => {
                                                deleteLastHistorySnapshot()
                                                addHistorySnapshot()
                                                showMeassage(
                                                    '导入成功！',
                                                    'success'
                                                )
                                            })
                                            .catch((e) => {
                                                logger.error('(预览图生成)', e)
                                                showMeassage(
                                                    '预览图生成失败',
                                                    'error'
                                                )
                                            })
                                            .finally(closeLoading)
                                    })
                                    .catch((e) => {
                                        logger.error('(ppt-json文件导入)', e)
                                        showMeassage(
                                            '导入失败,请检查文件是否正确',
                                            'error'
                                        )
                                        closeLoading()
                                    })
                            })
                            .catch((e) => {
                                logger.error('(ppt文件导入)', e)
                                if (e?.message) {
                                    showMeassage(e.message, 'error')
                                } else {
                                    showMeassage(
                                        '导入失败,请检查文件是否正确',
                                        'error'
                                    )
                                }
                                closeLoading()
                            })
                    } else if (extension === 'json') {
                        file.text().then((text: string) => {
                            importJson(text)
                                .then(() => {
                                    savePreviewImage()
                                        .then(() => {
                                            deleteLastHistorySnapshot()
                                            addHistorySnapshot()
                                            showMeassage(
                                                '导入成功！',
                                                'success'
                                            )
                                        })
                                        .catch((e) => {
                                            logger.error('(预览图生成)', e)
                                            showMeassage(
                                                '预览图生成失败',
                                                'error'
                                            )
                                        })
                                        .finally(closeLoading)
                                })
                                .catch((e) => {
                                    logger.error('(json文件导入)', e)
                                    showMeassage(
                                        '导入失败,请检查文件是否正确',
                                        'error'
                                    )
                                    closeLoading()
                                })
                        })
                    }
                }
                fileInput.dispatchEvent(new MouseEvent('click'))
                resolve()
            })
        }

        const importHandel = () => {
            Modal.confirm({
                title: '注意事项',
                content: createVNode('div', null, [
                    createVNode(
                        'p',
                        null,
                        '1. 导入过程中可能会丢失部分属性，如动画、文字特效、超链接、链接资源等，并且将会覆盖当前内容'
                    ),
                    createVNode(
                        'p',
                        null,
                        '2. 当前不支持格式为avi、mpg的视频解析、不支持格式为emf的图片解析'
                    ),
                    createVNode(
                        'p',
                        null,
                        '3. 导入原生ppt文件时,若是文件后缀为“.ppt”,请将文件另存为“.pptx”后再导入'
                    ),
                    createVNode('p', null, '是否继续导入？')
                ]),
                onOk: createImportFileHandler,
                okText: '确认',
                cancelText: '取消',
                width: '600px',
                centered: true,
                class: 'small'
            })
        }

        const createExportFileHandler = () => {
            return new Promise<void>((resolve) => {
                resolve()
                try {
                    showLoading('导出中,请勿关闭窗口...', 0)
                    exportJSON()
                } catch (e) {
                    logger.error('(导出JSON文件)', e)
                    showMeassage('导出失败,请稍后尝试', 'error')
                } finally {
                    closeLoading()
                }
            })
        }

        const exportHandel = () => {
            Modal.confirm({
                title: '注意事项',
                content: createVNode('div', null, [
                    createVNode(
                        'p',
                        null,
                        '导出过程中可能会丢失部分属性，如动画、文字特效、超链接等'
                    ),
                    createVNode('p', null, '是否继续导出？')
                ]),
                onOk: createExportFileHandler,
                okText: '确认',
                cancelText: '取消',
                width: '600px',
                centered: true,
                class: 'small'
            })
        }

        const editingScreening = computed(() => store.state.editingScreening)
        const onlyPpt = computed(
            () =>
                runtime?.editPptRule?.mode === 'onlyPpt' ||
                runtime?.editPptRule?.mode === 'multipleTabs'
        )
        const canShowGoBackBtn = computed(
            () => runtime?.editPptRule?.mode === 'onlyPpt'
        )
        const { proxy } = getCurrentInstance() as ComponentInternalInstance
        const ppt = proxy?.$root?.$el.parentNode

        const toggleEditingFullScreening = () => {
            store.commit(
                MutationTypes.SET_EDITING_FULL_SCREENING,
                !editingScreening.value
            )
        }

        const generateCurrentSlidePreview = () => {
            emitter.emit(EmitterEvents.GENERATE_CURRENT_PREVIEW, {
                slideId: store.state.slides[store.state.slideIndex].id
            })
        }
        const handleOpenModifyRecords = () => {
            modifyRecordsModalVisible.value = true
        }

        // 一开始先执行一下,确定是否在全屏状态
        watch(
            editingScreening,
            () => {
                if (editingScreening.value) {
                    ppt.classList.add('editing-browser-screening-full')
                } else {
                    ppt.classList.remove('editing-browser-screening-full')
                }
            },
            { immediate: true }
        )

        return {
            isMicroApp,
            runtime,
            pptName,
            onlyPpt,
            canShowGoBackBtn,
            showDocument,
            isDevelopment,
            knowledgePointsShow,
            hotkeyDrawerVisible,
            modifyRecordsModalVisible,
            noticeGoBack,
            setUploadMode,
            saveToCloud,
            saveKnowledgePoints,
            createSlide,
            deleteSlide,
            redo,
            undo,
            showGridLines,
            toggleGridLines,
            switchFullScreen,
            handelSetUploadMode,
            importHandel,
            exportHandel,
            editingScreening,
            toggleEditingFullScreening,
            showInitModifyDiffDot,
            generateCurrentSlidePreview,
            handleOpenModifyRecords,
            pptNameStyle,
            editorHeaderToolLeft,
            editorHeaderToolRight
        }
    }
})
