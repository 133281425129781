
import { computed, defineComponent, nextTick, onMounted, ref, watch } from 'vue'
import { MutationTypes, useStore } from '@/store'
import { fillDigit } from '@/utils/common'
import { ContextmenuItem } from '@/components/Contextmenu/types'
import useSlideHandler from '@/hooks/useSlideHandler'
import useScreening from '@/hooks/useScreening'
import useLink from '@/hooks/useLink'

import Draggable from 'vuedraggable'
import ThumbnailSlide from '@/views/components/ThumbnailSlide/index.vue'
import dom2image from '@evideo/dom-to-image'
import _ from 'lodash'
import { Slide } from '@/types/slides'
import logger from '@evideo/logger'

const convertVideoToCanvas = (node: Node) => {
	if (node instanceof HTMLVideoElement) {
		const c = document.createElement('canvas')
		c.width = node.clientWidth
		c.height = node.clientHeight
		c.setAttribute('style', c.getAttribute('style') || '')
		const ctx = c.getContext('2d')
		if (ctx) {
			/**
       0 = HAVE_NOTHING - 没有关于音频/视频是否就绪的信息
       1 = HAVE_METADATA - 关于音频/视频就绪的元数据
       2 = HAVE_CURRENT_DATA - 关于当前播放位置的数据是可用的，但没有足够的数据来播放下一帧/毫秒
       3 = HAVE_FUTURE_DATA - 当前及至少下一帧的数据是可用的
       4 = HAVE_ENOUGH_DATA - 可用数据足以开始播放
       */
			if (node.readyState >= 3) {
				ctx.drawImage(
					node,
					0,
					0,
					node.videoWidth,
					node.videoHeight,
					0,
					0,
					c.width,
					c.height
				)
			}
			// TODO: 视频无法获取第一帧，显示什么？
		}
		return c
	}
	return node
}

export default defineComponent({
	name: 'thumbnails',
	components: {
		Draggable,
		ThumbnailSlide
	},
	setup() {
		const store = useStore()
		const slides = computed(() => store.state.slides)
		const slideIndex = computed(() => store.state.slideIndex)
		const ctrlKeyState = computed(() => store.state.ctrlKeyState)
		const shiftKeyState = computed(() => store.state.shiftKeyState)
		const selectedSlidesIndex = computed(() => [
			...store.state.selectedSlidesIndex,
			slideIndex.value
		])

		const presetLayoutPopoverVisible = ref(false)
		const isImportPptx = ref(true)

		const {
			copySlide,
			pasteSlide,
			createSlide,
			createSlideByTemplate,
			copyAndPasteSlide,
			deleteSlide,
			cutSlide,
			selectAllSlide
		} = useSlideHandler()
		const { reSetLinkSlide } = useLink()

		// 切换页面
		const changSlideIndex = (index: number) => {
			store.commit(MutationTypes.SET_ACTIVE_ELEMENT_ID_LIST, [])

			if (slideIndex.value === index) return
			store.commit(MutationTypes.UPDATE_SLIDE_INDEX, index)
		}

		// 点击缩略图
		const handleClickSlideThumbnail = (e: MouseEvent, index: number) => {
			const isMultiSelected = selectedSlidesIndex.value.length > 1
			if (
				isMultiSelected &&
				selectedSlidesIndex.value.includes(index) &&
				e.button !== 0
			) {
				return
			}

			// 按住Ctrl键，点选幻灯片，再次点击已选中的页面则取消选中
			if (ctrlKeyState.value) {
				if (slideIndex.value === index) {
					if (!isMultiSelected) return

					const newSelectedSlidesIndex = selectedSlidesIndex.value.filter(
						(item) => item !== index
					)
					store.commit(
						MutationTypes.UPDATE_SELECTED_SLIDES_INDEX,
						newSelectedSlidesIndex
					)
					changSlideIndex(selectedSlidesIndex.value[0])
				} else {
					if (selectedSlidesIndex.value.includes(index)) {
						const newSelectedSlidesIndex = selectedSlidesIndex.value.filter(
							(item) => item !== index
						)
						store.commit(
							MutationTypes.UPDATE_SELECTED_SLIDES_INDEX,
							newSelectedSlidesIndex
						)
					} else {
						const newSelectedSlidesIndex = [...selectedSlidesIndex.value, index]
						store.commit(
							MutationTypes.UPDATE_SELECTED_SLIDES_INDEX,
							newSelectedSlidesIndex
						)
						changSlideIndex(index)
					}
				}
			}
			// 按住Shift键，选择范围内的全部幻灯片
			else if (shiftKeyState.value) {
				if (slideIndex.value === index && !isMultiSelected) return

				let minIndex = Math.min(...selectedSlidesIndex.value)
				let maxIndex = index

				if (index < minIndex) {
					maxIndex = Math.max(...selectedSlidesIndex.value)
					minIndex = index
				}

				const newSelectedSlidesIndex = []
				for (let i = minIndex; i <= maxIndex; i++) {
					newSelectedSlidesIndex.push(i)
				}
				store.commit(
					MutationTypes.UPDATE_SELECTED_SLIDES_INDEX,
					newSelectedSlidesIndex
				)
				changSlideIndex(index)
			}
			// 正常切换页面
			else {
				store.commit(MutationTypes.UPDATE_SELECTED_SLIDES_INDEX, [])
				changSlideIndex(index)
			}
		}

		const thumbnailsFocus = computed(() => store.state.thumbnailsFocus)

		// 设置缩略图工具栏聚焦状态（只有聚焦状态下，该部分的快捷键才能生效）
		const setThumbnailsFocus = (focus: boolean) => {
			if (thumbnailsFocus.value === focus) return
			store.commit(MutationTypes.SET_THUMBNAILS_FOCUS, focus)

			if (!focus) store.commit(MutationTypes.UPDATE_SELECTED_SLIDES_INDEX, [])
		}

		// 拖拽调整顺序后进行数据的同步
		const handleDragEnd = (eventData: {
			newIndex: number
			oldIndex: number
		}) => {
			const { newIndex, oldIndex } = eventData
			if (oldIndex === newIndex) return

			let _slides = JSON.parse(JSON.stringify(slides.value))

			const _slide = _slides[oldIndex]
			_slides.splice(oldIndex, 1)
			_slides.splice(newIndex, 0, _slide)

			_slides = reSetLinkSlide(_slides)

			store.commit(MutationTypes.SET_SLIDES, _slides)
			store.commit(MutationTypes.UPDATE_SLIDE_INDEX, newIndex)
		}

		const { enterScreening } = useScreening()

		const contextmenusThumbnails = (): ContextmenuItem[] => {
			return [
				{
					text: '粘贴',
					subText: 'Ctrl + V',
					handler: pasteSlide
				},
				{
					text: '全选',
					subText: 'Ctrl + A',
					handler: selectAllSlide
				},
				{
					text: '新建页面',
					subText: 'Enter',
					handler: createSlide
				},
				{
					text: '开始演示',
					subText: 'Ctrl + F',
					handler: () => enterScreening()
				}
			]
		}

		const contextmenusThumbnailItem = (): ContextmenuItem[] => {
			return [
				{
					text: '剪切',
					subText: 'Ctrl + X',
					handler: cutSlide
				},
				{
					text: '复制',
					subText: 'Ctrl + C',
					handler: copySlide
				},
				{
					text: '粘贴',
					subText: 'Ctrl + V',
					handler: pasteSlide
				},
				{
					text: '全选',
					subText: 'Ctrl + A',
					handler: selectAllSlide
				},
				{ divider: true },
				{
					text: '新建页面',
					subText: 'Enter',
					handler: createSlide
				},
				{
					text: '复制页面',
					subText: 'Ctrl + D',
					handler: copyAndPasteSlide
				},
				{
					text: '删除页面',
					subText: 'Delete',
					handler: () => deleteSlide()
				},
				{ divider: true },
				{
					text: '从当前页演示',
					subText: 'Ctrl + F',
					handler: () => enterScreening()
				}
			]
		}

		// 生成缩略图快照
		const slideRefs = computed(() => slides.value.map(() => ref()))
		const generatePreview = () => {
			const ret = []
			for (let i = 0; i < slides.value.length; i++) {
				const ref = slideRefs.value[i]
				const slide = slides.value[i]
				if (!slide.preview) {
					const html = ref.value?.$el
					if (html) {
						// FIXME: 这里scale 3是因为Thumbnail里用transform将自己压得很小。
						// 目前等于是压缩后又放大得到一个图片；
						// 如果侧边栏大小改变了，这里的scale也要改，不合理。有没更好的做法？
						const options = {
							height: html.clientHeight * 3,
							width: html.clientWidth * 3,
							style: { zoom: '3' },
							filter: convertVideoToCanvas
						}
						ret.push(
							dom2image.toPng(html, options as any).then((preview: string) => {
								slide.preview = preview
							})
						)
					}
				}
			}

			return Promise.all(ret)
		}
		const initThumbnails = () => {
			if (!store.state.pptUrl) {
				isImportPptx.value = false
				return
			}
			const slides = _.cloneDeep(store.state.slides)
      const slidesNum = slides.length
      // 导入的ppt，slide.previewSize === undefined
			const noPreviewedNum =
				slides.filter((slide: Slide) => slide?.previewSize === undefined)?.length || 0
			if (noPreviewedNum > 0) {
				logger.info(
					`该份ppt为导入的ppt，需要生成预览图 noPreviewedNum:${noPreviewedNum}; slidesNum :${slidesNum }`
				)
				isImportPptx.value = true
			} else {
				isImportPptx.value = false
			}
		}

		watch(
			() => store.state.pptUrl,
			() => {
				if (store.state.pptUrl) {
					initThumbnails()
				}
			}
		)

		watch(
			() => store.state.pageLoading,
			(newVal) => {
				if (!newVal && isImportPptx.value) {
          isImportPptx.value = false
				}
			}
		)

		watch(
			() => slideIndex.value,
			() =>
				nextTick().then(() =>
					document
						.querySelector('[class="thumbnail-item active selected"]')
						?.scrollIntoView({
							behavior: 'smooth',
							block: 'nearest',
							inline: 'nearest'
						})
				)
    )

    onMounted(()=>{
      initThumbnails()
    })

		return {
			slides,
			slideIndex,
			selectedSlidesIndex,
			presetLayoutPopoverVisible,
			createSlide,
			createSlideByTemplate,
			setThumbnailsFocus,
			handleClickSlideThumbnail,
			contextmenusThumbnails,
			contextmenusThumbnailItem,
			fillDigit,
			handleDragEnd,
			generatePreview,
			slideRefs,
			isImportPptx
		}
	}
})
