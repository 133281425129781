import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-fff40aa8")
const _hoisted_1 = { class: "font-size-select" }
const _hoisted_2 = { class: "adjust" }
_popScopeId()

import { PropType, ref, computed, watch, onUnmounted } from 'vue'

export default _defineComponent({
  props: {
	min: {
		type: Number,
		default: -Infinity
	},
	max: {
		type: Number,
		default: Infinity
	},
	value: {
		type: Number,
		required: true
	},
	disabled: {
		type: Boolean,
		default: false
	},
	options: {
		type: Array as PropType<
			Array<
				| {
						label: string | number
						value: number
				  }
				| string
				| number
			>
		>,
		required: true
	}
},
  emits: ['update:value', 'change'],
  setup(__props, { emit: emits }) {

const props = __props


const inputValue = ref(props.value)
const options = computed<
	Array<{
		label: string | number
		value: number
	}>
>(() => {
	const options = props.options || []
	return options.map((option) => {
		if (option instanceof Object) {
			return option as any
		}
		return {
			label: option,
			value: option
		}
	})
})

watch(
	() => props.value,
	(newVal) => {
		if (newVal !== inputValue.value) {
			inputValue.value = newVal
		}
	}
)

const onInputChange = () => {
	emits('update:value', inputValue.value)
	emits('change', inputValue.value)
}
const updateSize = (type: 'add' | 'minus') => {
	if (props.disabled) {
		return
	}
	if (type === 'add' && inputValue.value < props.max) {
		inputValue.value++
		onInputChange()
	} else if (type === 'minus' && inputValue.value > props.min) {
		inputValue.value--
		onInputChange()
	}
}

const pressTimer = ref()
const onMouseDown = (type: 'add' | 'minus') => {
	pressTimer.value = setInterval(() => {
		updateSize(type)
	}, 100)
}
const onMouseUp = () => {
	if (pressTimer.value) {
		clearInterval(pressTimer.value)
	}
}

onUnmounted(() => {
	if (pressTimer.value) {
		clearInterval(pressTimer.value)
	}
})

return (_ctx: any,_cache: any) => {
  const _component_SelectOption = _resolveComponent("SelectOption")!
  const _component_Select = _resolveComponent("Select")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Select, {
      value: inputValue.value,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (inputValue.value = $event)),
      onChange: onInputChange,
      disabled: props.disabled,
      class: "select"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(options), ({ label, value }) => {
          return (_openBlock(), _createBlock(_component_SelectOption, {
            key: label,
            value: value
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(label), 1)
            ]),
            _: 2
          }, 1032, ["value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["value", "disabled"]),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("button", {
        class: "adjust-button adjust-add",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (updateSize('add'))),
        onMousedown: _cache[2] || (_cache[2] = ($event: any) => (onMouseDown('add'))),
        onMouseup: onMouseUp,
        onMouseleave: onMouseUp
      }, null, 544), [
        [_directive_tooltip, '增大字号']
      ]),
      _withDirectives(_createElementVNode("button", {
        class: "adjust-button adjust-minus",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (updateSize('minus'))),
        onMousedown: _cache[4] || (_cache[4] = ($event: any) => (onMouseDown('minus'))),
        onMouseup: onMouseUp,
        onMouseleave: onMouseUp
      }, null, 544), [
        [_directive_tooltip, '减小字号']
      ])
    ])
  ]))
}
}

})