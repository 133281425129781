import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "link-button-element-operate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BorderLine = _resolveComponent("BorderLine")!
  const _component_ResizeHandler = _resolveComponent("ResizeHandler")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.borderLines, (line) => {
      return (_openBlock(), _createBlock(_component_BorderLine, {
        class: "operate-border-line",
        key: line.type,
        type: line.type,
        style: _normalizeStyle(line.style)
      }, null, 8, ["type", "style"]))
    }), 128)),
    (!_ctx.elementInfo.lock && (_ctx.isActiveGroupElement || !_ctx.isMultiSelect))
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.LinkButtonElementResizeHandlers, (point) => {
          return (_openBlock(), _createBlock(_component_ResizeHandler, {
            class: "operate-resize-handler",
            key: point.direction,
            type: point.direction,
            style: _normalizeStyle(point.style),
            onMousedown: _withModifiers(
					($event) => _ctx.scaleElement($event, _ctx.elementInfo, point.direction)
				, ["stop"])
          }, null, 8, ["type", "style", "onMousedown"]))
        }), 128))
      : _createCommentVNode("", true)
  ]))
}