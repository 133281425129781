
import { computed, defineComponent } from 'vue'
import { MutationTypes, useStore } from '@/store'
import { PPTLineElement } from '@/types/slides'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'

import ElementShadow from '../common/ElementShadow.vue'
import ColorButton from '../common/ColorButton.vue'

export default defineComponent({
    name: 'line-style-panel',
    components: {
        ElementShadow,
        ColorButton
    },
    setup() {
        const store = useStore()
        const handleElement = computed<PPTLineElement>(
            () => store.getters.handleElement
        )

        const { addHistorySnapshot } = useHistorySnapshot()

        const updateLine = (props: Partial<PPTLineElement>) => {
            store.commit(MutationTypes.UPDATE_ELEMENT, {
                id: handleElement.value.id,
                props
            })
            addHistorySnapshot()
        }

        return {
            handleElement,
            updateLine
        }
    }
})
