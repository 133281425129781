import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-1d98998b")
const _hoisted_1 = ["id"]
const _hoisted_2 = ["id", "d"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElementLinkIcon = _resolveComponent("ElementLinkIcon")!
  const _component_SvgWrapper = _resolveComponent("SvgWrapper")!

  return (_openBlock(), _createElementBlock("div", {
    class: "editable-element",
    ref: "elementRef",
    id: `editable-element-${_ctx.elementInfo.id}`,
    style: _normalizeStyle({
			zIndex: _ctx.elementIndex
		})
  }, [
    (_ctx.elementInfo?.link && _ctx.elementInfo.type !== 'link-button')
      ? (_openBlock(), _createBlock(_component_ElementLinkIcon, {
          key: 0,
          elementInfo: _ctx.elementInfo,
          onClick: _ctx.handleOpenLinkDialog
        }, null, 8, ["elementInfo", "onClick"]))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentElementComponent), {
      elementInfo: _ctx.elementInfo,
      selectElement: _ctx.selectElement,
      contextmenus: _ctx.contextmenus
    }, null, 8, ["elementInfo", "selectElement", "contextmenus"])),
    (_ctx.toolbarState === 'elAnimation' && _ctx.elementAnimationLineData?.path)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "animation-custom-path",
          style: _normalizeStyle({
				top: _ctx.elementAnimationLineData?.offsetTop + 'px',
				left: _ctx.elementAnimationLineData?.offsetLeft + 'px'
			})
        }, [
          _createVNode(_component_SvgWrapper, {
            overflow: "visible",
            left: 0,
            top: 0,
            width: _ctx.elementAnimationLineData?.svgWidth,
            height: _ctx.elementAnimationLineData?.svgHeight
          }, {
            default: _withCtx(() => [
              _createElementVNode("path", {
                id: `animation-custom-path-${_ctx.elementInfo.id}`,
                d: _ctx.elementAnimationLineData?.path,
                stroke: "#c1c1c1",
                fill: "none",
                "stroke-width": "2",
                "stroke-dasharray": "5,5",
                "stroke-linecap": "",
                "stroke-linejoin": "",
                "stroke-miterlimit": ""
              }, null, 8, _hoisted_2)
            ]),
            _: 1
          }, 8, ["width", "height"])
        ], 4))
      : _createCommentVNode("", true)
  ], 12, _hoisted_1))
}