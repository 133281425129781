
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'chart-pool',
  setup(props, { emit }) {
    const chartList = ['bar', 'line', 'pie']

    const selectChart = (chart: string) => {
      emit('select', chart)
    }

    return {
      chartList,
      selectChart,
    }
  },
})
