
import { useStore } from '@/store'
import { OperateLineHandler, OperateResizeHandler } from '@/types/edit'
import {
    ElementTypes,
    PPTElement,
    PPTShapeElement,
    Slide,
    FormatedAnimation,
    PPTElfElement
} from '@/types/slides'
import { computed, defineComponent, PropType } from 'vue'
import ChartElementOperate from './ChartElementOperate.vue'
import ElfOperate from './ElfOperate.vue'
import ImageElementOperate from './ImageElementOperate.vue'
import LineElementOperate from './LineElementOperate.vue'
import ShapeElementOperate from './ShapeElementOperate.vue'
import TableElementOperate from './TableElementOperate.vue'
import LinkHandler from './LinkHandler.vue'
import TextElementOperate from './TextElementOperate.vue'
import LinkButtonElementOperate from './LinkButtonElementOperate.vue'
import { ELEMENT_TYPE_ZH } from '@/configs/element'

export default defineComponent({
    name: 'operate',
    components: {
        LinkHandler
    },
    props: {
        elementInfo: {
            type: Object as PropType<PPTElement>,
            required: true
        },
        elementIndex: {
            type: Number,
            required: true
        },
        isSelected: {
            type: Boolean,
            required: true
        },
        isActive: {
            type: Boolean,
            required: true
        },
        isActiveGroupElement: {
            type: Boolean,
            required: true
        },
        isMultiSelect: {
            type: Boolean,
            required: true
        },
        rotateElement: {
            type: Function as PropType<(element: PPTElement) => void>,
            required: true
        },
        scaleElement: {
            type: Function as PropType<
                (
                    e: MouseEvent,
                    element: PPTElement,
                    command: OperateResizeHandler
                ) => void
            >,
            required: true
        },
        dragLineElement: {
            type: Function as PropType<
                (
                    e: MouseEvent,
                    element: PPTElement,
                    command: OperateLineHandler
                ) => void
            >,
            required: true
        },
        openLinkDialog: {
            type: Function as PropType<() => void>,
            required: true
        },
        moveShapeKeypoint: {
            type: Function as PropType<
                (e: MouseEvent, element: PPTShapeElement) => void
            >,
            required: true
        },
        selectElement: {
            type: Function as PropType<
                (e: MouseEvent, element: PPTElement, canMove?: boolean) => void
            >,
            required: true
        }
    },
    setup(props) {
        const store = useStore()
        const canvasScale = computed(() => store.state.canvasScale)
        const toolbarState = computed(() => store.state.toolbarState)
        const currentSlide = computed<Slide>(() => store.getters.currentSlide)
        const formatedAnimations = computed<FormatedAnimation[]>(
            () => store.getters.formatedAnimations
        )
        const currentOperateComponent = computed(() => {
            const elementTypeMap = {
                [ElementTypes.IMAGE]: ImageElementOperate,
                [ElementTypes.TEXT]: TextElementOperate,
                [ElementTypes.SHAPE]: ShapeElementOperate,
                [ElementTypes.LINE]: LineElementOperate,
                [ElementTypes.CHART]: ChartElementOperate,
                [ElementTypes.TABLE]: TableElementOperate,
                [ElementTypes.ELF]: ElfOperate,
                [ElementTypes.LINKBUTTON]: LinkButtonElementOperate
            }
            return elementTypeMap[props.elementInfo.type] || null
        })
        const elementIndexInAnimation = computed(() => {
            const animations = currentSlide.value.animations || []
            return animations.findIndex(
                (animation) => animation.elId === props.elementInfo.id
            )
        })
        const elementIndexListInAnimation = computed(() => {
            const indexList = []
            for (let i = 0; i < formatedAnimations.value.length; i++) {
                const elIds = formatedAnimations.value[i].animations.map(
                    (item) => item.elId
                )
                if (elIds.includes(props.elementInfo.id)) indexList.push(i)
            }
            return indexList
        })
        const elementTypeIndex = computed(() => {
            let eltype = ELEMENT_TYPE_ZH[props.elementInfo.type]
            if (props.elementInfo.type === 'elf') {
                eltype = ELEMENT_TYPE_ZH[(props.elementInfo as PPTElfElement).subtype]
            }
            return eltype + (props.elementIndex + 1)
        })
        return {
            currentOperateComponent,
            canvasScale,
            toolbarState,
            elementIndexInAnimation,
            elementIndexListInAnimation,
            elementTypeIndex
        }
    }
})
