import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-d57c000c")
const _hoisted_1 = { class: "canvas-popover-title" }
const _hoisted_2 = { class: "canvas-popover-description" }
const _hoisted_3 = { class: "canvas-popover-title" }
const _hoisted_4 = { class: "canvas-popover-description" }
_popScopeId()

import { computed, PropType, ref, watch } from 'vue'
import { CanvasTool } from './hooks/useCanvasTool'


const __default__ = {name: 'CanvasToolBtn'}

function setup(__props, { emit: emits }) {

const props = __props



const tool = computed<any>(() => props.tool || {})

const popoverVisible = ref(tool.value?.popoverVisible || false)
const downPopoverVisible = ref(tool.value?.downPopoverVisible || false)
watch(
    () => tool.value.popoverVisible,
    (newVal) => {
      popoverVisible.value = newVal
    }
)
watch(
    () => popoverVisible.value,
    (newVal) => {
      emits('popoverVisibleChange', newVal)
    }
)
watch(
    () => tool.value.downPopoverVisible,
    (newVal) => {
      downPopoverVisible.value = newVal
    }
)
watch(
    () => downPopoverVisible.value,
    (newVal) => {
      emits('downPopoverVisibleChange', newVal)
    }
)

return (_ctx: any,_cache: any) => {
  const _component_IconDown = _resolveComponent("IconDown")!
  const _component_Popover = _resolveComponent("Popover")!
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (!_unref(tool).isPopover)
    ? (_openBlock(), _createBlock(_component_Tooltip, {
        key: 0,
        mouseLeaveDelay: 0,
        mouseEnterDelay: 0.5,
        placement: "bottom",
        class: "canvas-btn",
        overlayClassName: "canvas-popover-overlay"
      }, {
        title: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", null, _toDisplayString(_unref(tool).name), 1)
          ]),
          _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(tool).description), 1)
        ]),
        default: _withCtx(() => [
          (_unref(tool).component)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(tool).component), _mergeProps({
                key: 0,
                class: "add-element-handler-children",
                key: _unref(tool).name
              }, _toHandlers(_unref(tool).componentData?.event || {}), _unref(tool).componentData?.data || {}), {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass(["canvas-tool-icon", _unref(tool).icon])
                  }, null, 2),
                  _createTextVNode(" " + _toDisplayString(_unref(tool).name) + " ", 1),
                  (_unref(tool).isDownPopover)
                    ? (_openBlock(), _createBlock(_component_Popover, {
                        key: 0,
                        trigger: "click",
                        visible: downPopoverVisible.value,
                        "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => (downPopoverVisible.value = $event)),
                        placement: "bottom",
                        class: "canvas-down-btn"
                      }, {
                        content: _withCtx(() => [
                          (_unref(tool).downPopoverComp)
                            ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(tool).downPopoverComp), _mergeProps({
                                key: 0,
                                key: _unref(tool).name
                              }, _toHandlers(_unref(tool).downPopoverCompData?.event || {}), _unref(tool).downPopoverCompData?.data || {}), null, 16))
                            : _createCommentVNode("", true)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_IconDown, { class: "arrow" })
                        ]),
                        _: 1
                      }, 8, ["visible"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 16))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["mouseEnterDelay"]))
    : (_openBlock(), _createBlock(_component_Popover, {
        key: 1,
        trigger: "click",
        visible: popoverVisible.value,
        "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => (popoverVisible.value = $event)),
        placement: "bottom"
      }, {
        content: _withCtx(() => [
          (_unref(tool).popoverComp)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(tool).popoverComp), _mergeProps({
                key: 0,
                key: _unref(tool).name
              }, _toHandlers(_unref(tool).popoverCompData?.event || {}), _unref(tool).popoverCompData?.data || {}), null, 16))
            : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Tooltip, {
            mouseLeaveDelay: 0,
            mouseEnterDelay: 0.5,
            placement: "bottom",
            overlayClassName: "canvas-popover-overlay",
            class: "canvas-btn"
          }, {
            title: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", null, _toDisplayString(_unref(tool).name), 1)
              ]),
              _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(tool).description), 1)
            ]),
            default: _withCtx(() => [
              (_unref(tool).component)
                ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(tool).component), _mergeProps({
                    key: 0,
                    class: "add-element-handler-children",
                    key: _unref(tool).name
                  }, _toHandlers(_unref(tool).componentData?.event || {}), _unref(tool).componentData?.data || {}), {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        class: _normalizeClass(["canvas-tool-icon", _unref(tool).icon])
                      }, null, 2),
                      _createTextVNode(" " + _toDisplayString(_unref(tool).name) + " ", 1),
                      (_unref(tool).isDownPopover)
                        ? (_openBlock(), _createBlock(_component_Popover, {
                            key: 0,
                            trigger: "click",
                            visible: downPopoverVisible.value,
                            "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (downPopoverVisible.value = $event)),
                            placement: "bottom",
                            class: "canvas-down-btn"
                          }, {
                            content: _withCtx(() => [
                              (_unref(tool).downPopoverComp)
                                ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(tool).downPopoverComp), _mergeProps({
                                    key: 0,
                                    key: _unref(tool).name
                                  }, _toHandlers(_unref(tool).downPopoverCompData?.event || {}), _unref(tool).downPopoverCompData?.data || {}), null, 16))
                                : _createCommentVNode("", true)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_IconDown, { class: "arrow" })
                            ]),
                            _: 1
                          }, 8, ["visible"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 16))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["mouseEnterDelay"])
        ]),
        _: 1
      }, 8, ["visible"]))
}
}


export default _defineComponent({
  ...__default__,
  props: {
  tool: {
    type: Object as PropType<CanvasTool>
  }
},
  emits: ['popoverVisibleChange', 'downPopoverVisibleChange'],
  setup})