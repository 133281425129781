
import { computed, defineComponent, PropType, ref } from 'vue'
import { useStore } from '@/store'

import { PPTLineElement } from '@/types/slides'
import { OperateBorderLines, OperateLineHandler, OperateLineHandlers } from '@/types/edit'

import BorderLine from './BorderLine.vue'

import ResizeHandler from './ResizeHandler.vue'
import useCommonOperate from '../hooks/useCommonOperate'
import PathHandler from './PathHandler.vue'
import useDragLineElement from '../hooks/useDragLineElement'

export default defineComponent({
	name: 'line-element-operate',
	inheritAttrs: false,
	components: {
		ResizeHandler,
		BorderLine,
		PathHandler
	},
	props: {
		elementInfo: {
			type: Object as PropType<PPTLineElement>,
			required: true
		},
		isActiveGroupElement: {
			type: Boolean,
			required: true
		},
		isMultiSelect: {
			type: Boolean,
			required: true
		},
		dragLineElement: {
			type: Function as PropType<
				(
					e: MouseEvent,
					element: PPTLineElement,
					command: OperateLineHandler
				) => void
			>,
			required: true
		}
	},
	setup(props) {
		const store = useStore()
		const canvasScale = computed(() => store.state.canvasScale)

		const scaleWidth = computed(
			() => (props.elementInfo.end[0] || 0) * canvasScale.value
		)
		const scaleHeight = computed(
			() => (props.elementInfo.end[1] || 0) * canvasScale.value
		)
		const { borderLines } = useCommonOperate(scaleWidth, scaleHeight)

		/** 调整自定义曲线操作框 */
		const customBorderlines = computed<any>(() => {
			return borderLines.value.map((line) => {
				if (line?.type === OperateBorderLines.T) {
					return {
						...line,
						style: {
							left: '-10px',
							top: '-10px',
							width: parseFloat(line.style?.width || '0') + 20 + 'px'
						}
					}
				} else if (line?.type === OperateBorderLines.B) {
					return {
						...line,
						style: {
							left: '-10px',
							top: parseFloat(line.style?.top || '0') + 10 + 'px',
							width: parseFloat(line.style?.width || '0') + 20 + 'px'
						}
					}
				} else if (line?.type === OperateBorderLines.L) {
					return {
						...line,
						style: {
							left: '-10px',
							top: '-10px',
							height: parseFloat(line.style?.height || '0') + 20 + 'px'
						}
					}
				} else if (line?.type === OperateBorderLines.R) {
					return {
						...line,
						style: {
							top: '-10px',
							left: parseFloat(line.style?.left || '0') + 10 + 'px',
							height: parseFloat(line.style?.height || '0') + 20 + 'px'
						}
					}
				}
			})
		})

		const { dragLinePoint } = useDragLineElement(ref([]))

		const resizeHandlers = computed(() => {
			const handlers = [
				{
					handler: OperateLineHandlers.START,
					style: {
						left: props.elementInfo.start[0] * canvasScale.value + 'px',
						top: props.elementInfo.start[1] * canvasScale.value + 'px'
					}
				},
				{
					handler: OperateLineHandlers.END,
					style: {
						left: props.elementInfo.end[0] * canvasScale.value + 'px',
						top: props.elementInfo.end[1] * canvasScale.value + 'px'
					}
				}
			]
			const customHandlers = [
				{
					handler: OperateLineHandlers.LEFT_TOP,
					direction: OperateLineHandlers.LEFT_TOP,
					style: {
						left: (props.elementInfo.start[0] - 20) * canvasScale.value + 'px',
						top: (props.elementInfo.start[1] - 20) * canvasScale.value + 'px'
					}
				},
				{
					handler: OperateLineHandlers.TOP,
					direction: OperateLineHandlers.TOP,
					style: {
						left:
							((props.elementInfo.start[0]) + 20 +
								(props.elementInfo.end[0] - props.elementInfo.start[0]) / 2) *
								canvasScale.value +
							'px',
						top: (props.elementInfo.start[1] - 20) * canvasScale.value + 'px'
					}
				},
				{
					handler: OperateLineHandlers.RIGHT_TOP,
					direction: OperateLineHandlers.RIGHT_TOP,
					style: {
						left: (props.elementInfo.end[0] + 20) * canvasScale.value + 'px',
						top: (props.elementInfo.start[1] - 20) * canvasScale.value + 'px'
					}
				},
				{
					handler: OperateLineHandlers.LEFT,
					direction: OperateLineHandlers.LEFT,
					style: {
						left: (props.elementInfo.start[0] - 20) * canvasScale.value + 'px',
						top:
							(props.elementInfo.start[1] + 20 +
								(props.elementInfo.end[1] - props.elementInfo.start[1]) / 2) *
								canvasScale.value +
							'px'
					}
				},
				{
					handler: OperateLineHandlers.RIGHT,
					direction: OperateLineHandlers.RIGHT,
					style: {
						left: (props.elementInfo.end[0] + 20) * canvasScale.value + 'px',
						top:
							(props.elementInfo.start[1] + 20 +
								(props.elementInfo.end[1] - props.elementInfo.start[1]) / 2) *
								canvasScale.value +
							'px'
					}
				},
				{
					handler: OperateLineHandlers.LEFT_BOTTOM,
					direction: OperateLineHandlers.LEFT_BOTTOM,
					style: {
						left: (props.elementInfo.start[0] - 20) * canvasScale.value + 'px',
						top: (props.elementInfo.end[1] + 20) * canvasScale.value + 'px'
					}
				},
				{
					handler: OperateLineHandlers.BOTTOM,
					direction: OperateLineHandlers.BOTTOM,
					style: {
						left:
						((props.elementInfo.start[0]) + 20 +
								(props.elementInfo.end[0] - props.elementInfo.start[0]) / 2) *
								canvasScale.value +
							'px',
						top: (props.elementInfo.end[1] + 20) * canvasScale.value + 'px'
					}
				},
				{
					handler: OperateLineHandlers.RIGHT_BOTTOM,
					direction: OperateLineHandlers.RIGHT_BOTTOM,
					style: {
						left: ( props.elementInfo.end[0] + 20) * canvasScale.value + 'px',
						top: (props.elementInfo.end[1] + 20) * canvasScale.value + 'px'
					}
				}
			]

			if (props.elementInfo.curve || props.elementInfo.broken) {
				const midHandler = (props.elementInfo.curve ||
					props.elementInfo.broken) as [number, number]

				handlers.push({
					handler: OperateLineHandlers.MID,
					style: {
						left: midHandler[0] * canvasScale.value + 'px',
						top: midHandler[1] * canvasScale.value + 'px'
					}
				})
			}
			return props.elementInfo?.customPath ? customHandlers : handlers
		})

		const pathHandlers = computed(() => {
			if (!props.elementInfo?.customPath) {
				return []
			}
			const handlers: any = []
			props.elementInfo.custom?.forEach((point: { x: number; y: number }) => {
				handlers.push({
					handler: OperateLineHandlers.POINT,
					style: {
						left: point.x * canvasScale.value + 'px',
						top: point.y * canvasScale.value + 'px'
					}
				})
			})
			return handlers
		})

		return {
			resizeHandlers,
			borderLines,
			pathHandlers,
			dragLinePoint,
			customBorderlines
		}
	}
})
