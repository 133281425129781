
import { computed, defineComponent, PropType } from 'vue'
import { ElementTypes, PPTElement, Slide } from '@/types/slides'
import { ContextmenuItem } from '@/components/Contextmenu/types'

import { MutationTypes, useStore } from '@/store'
import useLockElement from '@/hooks/useLockElement'
import useDeleteElement from '@/hooks/useDeleteElement'
import useCombineElement from '@/hooks/useCombineElement'
import useOrderElement from '@/hooks/useOrderElement'
import useAlignElementToCanvas from '@/hooks/useAlignElementToCanvas'
import useCopyAndPasteElement from '@/hooks/useCopyAndPasteElement'
import useSelectAllElement from '@/hooks/useSelectAllElement'
import useLink from '@/hooks/useLink'

import {
  elfElementOnlyShowOne
} from '@/views/components/element/ElfElement/register';

import { ElementOrderCommands, ElementAlignCommands } from '@/types/edit'

import ImageElement from '@/views/components/element/ImageElement/index.vue'
import TextElement from '@/views/components/element/TextElement/index.vue'
import ShapeElement from '@/views/components/element/ShapeElement/index.vue'
import LineElement from '@/views/components/element/LineElement/index.vue'
import ChartElement from '@/views/components/element/ChartElement/index.vue'
import TableElement from '@/views/components/element/TableElement/index.vue'
import ElfElement from '@/views/components/element/ElfElement/index.vue'
import ElementLinkIcon from '@/views/components/element/ElementLinkIcon.vue'
import LinkButtonElement from '@/views/components/element/LinkButtonElement/index.vue'

export default defineComponent({
	name: 'editable-element',
	props: {
		elementInfo: {
			type: Object as PropType<PPTElement>,
			required: true
		},
		elementIndex: {
			type: Number,
			required: true
		},
		isMultiSelect: {
			type: Boolean,
			required: true
		},
		selectElement: {
			type: Function as PropType<
				(e: MouseEvent, element: PPTElement, canMove?: boolean) => void
			>,
			required: true
		},
		openLinkDialog: {
			type: Function as PropType<() => void>,
			required: true
		},
		openLinkButtonDialog: {
			type: Function as PropType<() => void>,
			required: true
		}
	},
	components: { ElementLinkIcon },
	setup(props) {
		const currentElementComponent = computed(() => {
			const elementTypeMap = {
				[ElementTypes.IMAGE]: ImageElement,
				[ElementTypes.TEXT]: TextElement,
				[ElementTypes.SHAPE]: ShapeElement,
				[ElementTypes.LINE]: LineElement,
				[ElementTypes.CHART]: ChartElement,
				[ElementTypes.TABLE]: TableElement,
				[ElementTypes.ELF]: ElfElement,
				[ElementTypes.LINKBUTTON]: LinkButtonElement
			}
			return elementTypeMap[props.elementInfo.type] || null
		})
		const toolbarState = computed(() => store.state.toolbarState)
		const currentSlide = computed<Slide>(() => store.getters.currentSlide)

		const store = useStore()
		const { orderElement } = useOrderElement()
		const { alignElementToCanvas } = useAlignElementToCanvas()
		const { combineElements, uncombineElements } = useCombineElement()
		const { deleteElement } = useDeleteElement()
		const { lockElement, unlockElement } = useLockElement()
		const { copyElement, pasteElement, cutElement } = useCopyAndPasteElement()
		const { selectAllElement } = useSelectAllElement()
		const { removeLink } = useLink()


		const contextmenus = (): ContextmenuItem[] => {
			if (props.elementInfo.lock) {
				return [
					{
						text: '解锁',
						handler: () => unlockElement(props.elementInfo)
					}
				]
			}

			const baseMenus: any[] = [
				{
					text: '剪切',
					subText: 'Ctrl + X',
					handler: cutElement
				},
				{ divider: true },
				{
					text: '水平居中',
					handler: () => alignElementToCanvas(ElementAlignCommands.HORIZONTAL),
					children: [
						{
							text: '水平垂直居中',
							handler: () => alignElementToCanvas(ElementAlignCommands.CENTER)
						},
						{
							text: '水平居中',
							handler: () =>
								alignElementToCanvas(ElementAlignCommands.HORIZONTAL)
						},
						{
							text: '左对齐',
							handler: () => alignElementToCanvas(ElementAlignCommands.LEFT)
						},
						{
							text: '右对齐',
							handler: () => alignElementToCanvas(ElementAlignCommands.RIGHT)
						}
					]
				},
				{
					text: '垂直居中',
					handler: () => alignElementToCanvas(ElementAlignCommands.VERTICAL),
					children: [
						{
							text: '水平垂直居中',
							handler: () => alignElementToCanvas(ElementAlignCommands.CENTER)
						},
						{
							text: '垂直居中',
							handler: () => alignElementToCanvas(ElementAlignCommands.VERTICAL)
						},
						{
							text: '顶部对齐',
							handler: () => alignElementToCanvas(ElementAlignCommands.TOP)
						},
						{
							text: '底部对齐',
							handler: () => alignElementToCanvas(ElementAlignCommands.BOTTOM)
						}
					]
				},
				{ divider: true },
				{
					text: '置于顶层',
					disable: props.isMultiSelect && !props.elementInfo.groupId,
					handler: () =>
						orderElement(props.elementInfo, ElementOrderCommands.TOP),
					children: [
						{
							text: '置于顶层',
							handler: () =>
								orderElement(props.elementInfo, ElementOrderCommands.TOP)
						},
						{
							text: '上移一层',
							handler: () =>
								orderElement(props.elementInfo, ElementOrderCommands.UP)
						}
					]
				},
				{
					text: '置于底层',
					disable: props.isMultiSelect && !props.elementInfo.groupId,
					handler: () =>
						orderElement(props.elementInfo, ElementOrderCommands.BOTTOM),
					children: [
						{
							text: '置于底层',
							handler: () =>
								orderElement(props.elementInfo, ElementOrderCommands.BOTTOM)
						},
						{
							text: '下移一层',
							handler: () =>
								orderElement(props.elementInfo, ElementOrderCommands.DOWN)
						}
					]
				},
				{ divider: true },
				{
					text: '设置链接',
					children: [
						{
							text: props.elementInfo?.link ? '更换链接' : '新增链接',
							handler:
								props.elementInfo.type !== 'link-button'
									? props.openLinkDialog
									: props.openLinkButtonDialog
						},
						{
							text: '移除链接',
							handler:
								props.elementInfo.type !== 'link-button'
									? () => removeLink(props.elementInfo)
									: deleteElement
						}
					]
				},
				{
					text: props.elementInfo.groupId ? '取消组合' : '组合',
					subText: 'Ctrl + G',
					handler: props.elementInfo.groupId
						? uncombineElements
						: combineElements,
					hide: !props.isMultiSelect
				},
				{
					text: '全选',
					subText: 'Ctrl + A',
					handler: selectAllElement
				},
				{
					text: '锁定',
					subText: 'Ctrl + L',
					handler: lockElement
				},
				{
					text: '删除',
					subText: 'Delete',
					handler: deleteElement
				}
			]
			// 某些特殊元素一页幻灯片仅允许存在一个，所以不可复制粘贴
			if (
				!(props.elementInfo.type === 'elf' &&
				elfElementOnlyShowOne.includes(props.elementInfo?.subtype))
			) {
				baseMenus.splice(
					1,
					0,
					{
						text: '复制',
						subText: 'Ctrl + C',
						handler: copyElement
					},
					{
						text: '粘贴',
						subText: 'Ctrl + V',
						handler: pasteElement
					}
				)
			}
			return baseMenus
		}
		const handleOpenLinkDialog = () => {
			store.commit(MutationTypes.SET_HANDLE_ELEMENT_ID, props.elementInfo.id)
			setTimeout(() => {
				props.openLinkDialog()
			}, 0)
		}

		const elementAnimationLineData = computed<any>(() => {
			const animations = currentSlide.value.animations || []

			const animation = animations.find(
				(animation) =>
					animation.elId === props.elementInfo.id &&
					animation?.type === 'custom'
			)
			if (!animation) {
				return {}
			}
			const { points, width, height } = animation?.path || {}
			let _path = ''

			const pointX: number[] = []
			const pointY: number[] = []
			points.forEach((point: any, index: number) => {
				if (index === 0) {
					pointX.push(0)
					pointY.push(0)
				} else {
					pointX.push(point[0])
					pointY.push(point[1])
				}
			})
			const offsetLeft =
				(props.elementInfo?.width || 0) / 2 +
				Math.min(...pointX) +
				props.elementInfo.left
			const offsetTop =
				((props.elementInfo as any)?.height || 0) / 2 +
				Math.min(...pointY) +
				props.elementInfo.top
			const offsetX = Math.min(...pointX)
			const offsetY = Math.min(...pointY)
			pointX.forEach((_x, index) => {
				if (index === 0) {
					_path += `M${0 - offsetX}, ${0 - offsetY} `
				} else {
					_path += `L${0 + _x - offsetX},${0 + pointY[index] - offsetY} `
				}
			})
			return {
				...animation,
				path: _path,
				svgWidth: width || 0,
				svgHeight: height || 0,
				offsetLeft,
				offsetTop,
				offsetX: Math.min(...pointX),
				offsetY: Math.min(...pointY)
			}
		})

		return {
			currentElementComponent,
			contextmenus,
			handleOpenLinkDialog,
			toolbarState,
			elementAnimationLineData
		}
	}
})
