
import { useStore } from "@/store";
import { PPTElfElement } from "@/types/slides";
import { findElfByType } from "@/views/components/element/ElfElement/register";
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "elf-style-panel",
  setup() {
    const store = useStore();
    const handleElement = computed<PPTElfElement>(
      () => store.getters.handleElement
    );

    const currentElfToolbar = computed(() => {
      if (!handleElement.value) return null;

      const config = findElfByType(handleElement.value.subtype);
      return config?.toolbarComponent;
    });

    return {
      currentElfToolbar,
    };
  },
});
