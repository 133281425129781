
import { computed, defineComponent, onUnmounted, ref, watch } from 'vue'
import { IBarChartOptions, ILineChartOptions, IPieChartOptions } from 'chartist'
import { MutationTypes, useStore } from '@/store'
import { ChartData, PPTChartElement } from '@/types/slides'
import emitter, { EmitterEvents } from '@/utils/emitter'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'

import ElementOutline from '../../common/ElementOutline.vue'
import ColorButton from '../../common/ColorButton.vue'
import ChartDataEditor from './ChartDataEditor.vue'

export default defineComponent({
    name: 'chart-style-panel',
    components: {
        ElementOutline,
        ChartDataEditor,
        ColorButton
    },
    setup() {
        const store = useStore()
        const handleElement = computed<PPTChartElement>(
            () => store.getters.handleElement
        )

        const chartDataEditorVisible = ref(false)

        const { addHistorySnapshot } = useHistorySnapshot()

        const fill = ref<string>()

        const themeColor = ref<string>('')
        const gridColor = ref('')

        const lineSmooth = ref(true)
        const showLine = ref(true)
        const showArea = ref(false)
        const horizontalBars = ref(false)
        const donut = ref(false)

        watch(
            handleElement,
            () => {
                if (
                    !handleElement.value ||
                    handleElement.value.type !== 'chart'
                ) {
                    return
                }
                fill.value = handleElement.value.fill || '#000'

                if (handleElement.value.options) {
                    const {
                        lineSmooth: _lineSmooth,
                        showLine: _showLine,
                        showArea: _showArea,
                        horizontalBars: _horizontalBars,
                        donut: _donut
                    } = handleElement.value.options

                    if (_lineSmooth !== undefined) {
                        lineSmooth.value = _lineSmooth as boolean
                    }
                    if (_showLine !== undefined) {
                        showLine.value = _showLine
                    }
                    if (_showArea !== undefined) {
                        showArea.value = _showArea
                    }
                    if (_horizontalBars !== undefined) {
                        horizontalBars.value = _horizontalBars
                    }
                    if (_donut !== undefined) {
                        donut.value = _donut
                    }
                }

                themeColor.value = handleElement.value.themeColor
                gridColor.value =
                    handleElement.value.gridColor || 'rgba(0, 0, 0, 0.4)'
            },
            { deep: true, immediate: true }
        )

        // 设置图表数据
        const updateData = (data: ChartData) => {
            chartDataEditorVisible.value = false
            const props = { data }
            store.commit(MutationTypes.UPDATE_ELEMENT, {
                id: handleElement.value.id,
                props
            })
            addHistorySnapshot()
        }

        // 设置填充色
        const updateFill = (value: string) => {
            const props = { fill: value }
            store.commit(MutationTypes.UPDATE_ELEMENT, {
                id: handleElement.value.id,
                props
            })
            addHistorySnapshot()
        }

        // 设置其他选项：柱状图转条形图、折线图转面积图、折线图转散点图、饼图转环形图、折线图开关平滑曲线
        const updateOptions = (
            optionProps: ILineChartOptions & IBarChartOptions & IPieChartOptions
        ) => {
            const options = handleElement.value.options || {}
            const newOptions = { ...options, ...optionProps }
            const props = { options: newOptions }
            store.commit(MutationTypes.UPDATE_ELEMENT, {
                id: handleElement.value.id,
                props
            })
            addHistorySnapshot()
        }

        // 设置主题色
        const updateTheme = (themeColor: string) => {
            const props = { themeColor }
            store.commit(MutationTypes.UPDATE_ELEMENT, {
                id: handleElement.value.id,
                props
            })
            addHistorySnapshot()
        }

        // 设置网格颜色
        const updateGridColor = (gridColor: string) => {
            const props = { gridColor }
            store.commit(MutationTypes.UPDATE_ELEMENT, {
                id: handleElement.value.id,
                props
            })
            addHistorySnapshot()
        }

        const openDataEditor = () => (chartDataEditorVisible.value = true)

        emitter.on(EmitterEvents.OPEN_CHART_DATA_EDITOR, openDataEditor)
        onUnmounted(() => {
            emitter.off(EmitterEvents.OPEN_CHART_DATA_EDITOR, openDataEditor)
        })

        return {
            chartDataEditorVisible,
            handleElement,
            updateData,
            fill,
            updateFill,
            lineSmooth,
            showLine,
            showArea,
            horizontalBars,
            donut,
            updateOptions,
            themeColor,
            gridColor,
            updateTheme,
            updateGridColor
        }
    }
})
