import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-d1a42ac4")
const _hoisted_1 = ["href"]
const _hoisted_2 = {
  key: 1,
  class: "link"
}
const _hoisted_3 = { class: "btns" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Divider = _resolveComponent("Divider")!

  return (_openBlock(), _createElementBlock("div", {
    class: "link-handler",
    style: _normalizeStyle({ top: _ctx.elementInfo.height * _ctx.canvasScale + 10 + 'px' })
  }, [
    (_ctx.elementInfo.link.type === 'web-url')
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          class: "link",
          href: _ctx.elementInfo.link.target,
          target: "_blank"
        }, _toDisplayString(_ctx.elementInfo.link.target), 9, _hoisted_1))
      : (_openBlock(), _createElementBlock("span", _hoisted_2, " 幻灯片页面 第" + _toDisplayString(+_ctx.elementInfo.link.target?.split('-')?.[0] + 1) + "页 ", 1)),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "btn",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openLinkDialog()))
      }, "更换"),
      _createVNode(_component_Divider, { type: "vertical" }),
      _createElementVNode("div", {
        class: "btn",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.removeLink(_ctx.elementInfo)))
      }, "移除")
    ])
  ], 4))
}