
import { PropType } from 'vue'
import { OperateBorderLine } from '@/types/edit'

export default {
  name: 'border-line',
  props: {
    type: {
      type: String as PropType<OperateBorderLine>,
      required: true,
    },
    isWide: {
      type: Boolean,
      default: false,
    },
  },
}
