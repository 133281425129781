import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withScopeId as _withScopeId } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconPlatte = _resolveComponent("IconPlatte")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock(_component_Button, { class: "color-btn" }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "color-block",
        style: _normalizeStyle({ backgroundColor: $props.color })
      }, null, 4),
      _createVNode(_component_IconPlatte, { class: "color-btn-icon" })
    ]),
    _: 1
  }))
}