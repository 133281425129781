import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-24d82158")
const _hoisted_1 = { class: "shape-pool" }
const _hoisted_2 = { class: "category-name" }
const _hoisted_3 = { class: "shape-list" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["d", "stroke-dasharray", "marker-start", "marker-end"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["transform"]
const _hoisted_8 = ["d"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinePointMarker = _resolveComponent("LinePointMarker")!
  const _component_SvgWrapper = _resolveComponent("SvgWrapper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shapeList, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "category",
        key: item.type
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(item.type), 1),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (shape, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "shape-item",
              key: index
            }, [
              (item?.elementType === 'line')
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "line-content",
                    onClick: ($event: any) => (_ctx.selectLine(shape))
                  }, [
                    _createVNode(_component_SvgWrapper, {
                      overflow: "visible",
                      width: "20",
                      height: "20"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("defs", null, [
                          (shape.points[0])
                            ? (_openBlock(), _createBlock(_component_LinePointMarker, {
                                key: 0,
                                class: "line-marker",
                                id: `preset-line-${index}`,
                                position: "start",
                                type: shape.points[0],
                                color: "currentColor",
                                baseSize: 2
                              }, null, 8, ["id", "type"]))
                            : _createCommentVNode("", true),
                          (shape.points[1])
                            ? (_openBlock(), _createBlock(_component_LinePointMarker, {
                                key: 1,
                                class: "line-marker",
                                id: `preset-line-${index}`,
                                position: "end",
                                type: shape.points[1],
                                color: "currentColor",
                                baseSize: 2
                              }, null, 8, ["id", "type"]))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("path", {
                          class: "line-path",
                          d: shape.path,
                          stroke: "currentColor",
                          fill: "none",
                          "stroke-width": "2",
                          "stroke-dasharray": shape.style === 'solid' ? '0, 0' : '4, 1',
                          "stroke-linecap": "",
                          "stroke-linejoin": "",
                          "stroke-miterlimit": "",
                          "marker-start": 
									shape.points[0]
										? `url(#${`preset-line-${index}`}-${shape.points[0]}-start)`
										: ''
								,
                          "marker-end": 
									shape.points[1]
										? `url(#${`preset-line-${index}`}-${shape.points[1]}-end)`
										: ''
								
                        }, null, 8, _hoisted_5)
                      ]),
                      _: 2
                    }, 1024)
                  ], 8, _hoisted_4))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "shape-content",
                    onClick: ($event: any) => (_ctx.selectShape(shape))
                  }, [
                    _createVNode(_component_SvgWrapper, {
                      overflow: "visible",
                      width: "18",
                      height: "18"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("g", {
                          transform: 
									`scale(${18 / shape.viewBox[0]}, ${18 /
										shape.viewBox[1]}) translate(0,0) matrix(1,0,0,1,0,0)`
								
                        }, [
                          _createElementVNode("path", {
                            class: "shape-path",
                            "vector-effect": "non-scaling-stroke",
                            "stroke-linecap": "butt",
                            "stroke-miterlimit": "8",
                            "stroke-linejoin": "",
                            fill: "transparent",
                            stroke: "#999",
                            "stroke-width": "2",
                            d: shape.path
                          }, null, 8, _hoisted_8)
                        ], 8, _hoisted_7)
                      ]),
                      _: 2
                    }, 1024)
                  ], 8, _hoisted_6))
            ]))
          }), 128))
        ])
      ]))
    }), 128))
  ]))
}