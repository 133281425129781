import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-71dda8b0")
const _hoisted_1 = { class: "material-library-pool" }
const _hoisted_2 = { class: "material-search" }
const _hoisted_3 = { class: "material-content" }
_popScopeId()

import { computed, ref } from 'vue';
  import { SearchOutlined, CloseCircleOutlined } from '@ant-design/icons-vue';
  import VideoMaterial from './videoMaterial.vue';
  import AudioMaterial from './audioMaterial.vue';
  import ImageMaterial from './imageMaterial.vue';

  
export default _defineComponent({
  emits: ['confirm'],
  setup(__props, { emit: emits }) {

  
  const materialTabs = [
    {
      name: '图片',
      key: 'image',
      component: ImageMaterial,
      componentRef: ref(),
    },
    {
      name: '音频',
      key: 'audio',
      component: AudioMaterial,
      componentRef: ref(),
    },
    {
      name: '视频',
      key: 'video',
      component: VideoMaterial,
      componentRef: ref(),
    },
  ];

  const keyword = ref('');
  const activeKey = ref('image');
  const activeComponentRef = computed(() => {
    return materialTabs.find((m) => m.key === activeKey.value)?.componentRef;
  });

  /** ! 子组件的搜索必须要暴露出一个doSearch的方法以便用于搜索*/
  const onSearch = () => {
    const doSearch = activeComponentRef.value?.value?.doSearch;
    if (doSearch) {
      const keywordParam = keyword.value.trim();
      activeComponentRef.value?.value?.doSearch(keywordParam);
    }
  };

  const resetSearch = () => {
    keyword.value = '';
    onSearch();
  };

  /** ! 子组件的插入完后 要触发一个confirm的事件出来，用于关闭弹窗*/
  const onConfirm = (data: any) => {
    emits('confirm', data);
  };

return (_ctx: any,_cache: any) => {
  const _component_Input = _resolveComponent("Input")!
  const _component_TabsPane = _resolveComponent("TabsPane")!
  const _component_Tabs = _resolveComponent("Tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Input, {
        value: keyword.value,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (keyword.value = $event)),
        placeholder: "输入关键词，多个关键词用空格隔开",
        onPressEnter: onSearch
      }, {
        prefix: _withCtx(() => [
          _createVNode(_unref(SearchOutlined), { style: {"font-size":"20px"} })
        ]),
        suffix: _withCtx(() => [
          (keyword.value)
            ? (_openBlock(), _createBlock(_unref(CloseCircleOutlined), {
                key: 0,
                style: {"font-size":"20px"},
                onClick: resetSearch
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["value"]),
      _createElementVNode("div", {
        class: "search-btn",
        onClick: onSearch
      }, "搜索")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Tabs, {
        activeKey: activeKey.value,
        "onUpdate:activeKey": _cache[1] || (_cache[1] = ($event: any) => (activeKey.value = $event)),
        tabPosition: "left",
        class: "tabs",
        onChange: onSearch
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(materialTabs, (item) => {
            return _createVNode(_component_TabsPane, {
              key: item.key,
              tab: item.name
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(item.component), {
                  onConfirm: onConfirm,
                  keyword: keyword.value.trim(),
                  materialType: item.key,
                  ref: item.componentRef
                }, null, 8, ["keyword", "materialType"]))
              ]),
              _: 2
            }, 1032, ["tab"])
          }), 64))
        ]),
        _: 1
      }, 8, ["activeKey"])
    ])
  ]))
}
}

})