
import { computed, defineComponent, ref, watch } from 'vue'
import _ from 'lodash'
import { MutationTypes, useStore } from '@/store'
import { PPTElement } from '@/types/slides'
import { MIN_SIZE } from '@/configs/element'
import useOrderElement from '@/hooks/useOrderElement'
import useAlignElementToCanvas from '@/hooks/useAlignElementToCanvas'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'
import { SHAPE_PATH_FORMULAS } from '@/configs/shapes'

export default defineComponent({
    name: 'element-positopn-panel',
    setup() {
        const store = useStore()
        const handleElement = computed<PPTElement>(
            () => store.getters.handleElement
        )

        const left = ref(0)
        const top = ref(0)
        const width = ref(0)
        const height = ref(0)
        const rotate = ref(0)
        const fixedRatio = ref(false)

        const minSize = computed(() => {
            if (!handleElement.value) return 20
            return MIN_SIZE[handleElement.value.type] || 20
        })

        watch(
            handleElement,
            () => {
                if (!handleElement.value) return

                left.value = _.round(handleElement.value.left, 1)
                top.value = _.round(handleElement.value.top, 1)

                fixedRatio.value =
                    'fixedRatio' in handleElement.value &&
                    !!handleElement.value.fixedRatio

                if (handleElement.value.type !== 'line') {
                    width.value = _.round(handleElement.value.width, 1)
                    height.value = _.round(handleElement.value.height, 1)
                    rotate.value =
                        'rotate' in handleElement.value &&
                        handleElement.value.rotate !== undefined
                            ? _.round(handleElement.value.rotate, 1)
                            : 0
                }
            },
            { deep: true, immediate: true }
        )

        const { orderElement } = useOrderElement()
        const { alignElementToCanvas } = useAlignElementToCanvas()

        const { addHistorySnapshot } = useHistorySnapshot()

        // 设置元素位置
        const updateLeft = (value: number) => {
            const props = { left: value }
            store.commit(MutationTypes.UPDATE_ELEMENT, {
                id: handleElement.value.id,
                props
            })
            addHistorySnapshot()
        }
        const updateTop = (value: number) => {
            const props = { top: value }
            store.commit(MutationTypes.UPDATE_ELEMENT, {
                id: handleElement.value.id,
                props
            })
            addHistorySnapshot()
        }

        // 设置元素宽度、高度、旋转角度
        // 对形状设置宽高时，需要检查是否需要更新形状路径
        const updateShapePathData = (width: number, height: number) => {
            if (
                handleElement.value &&
                handleElement.value.type === 'shape' &&
                'pathFormula' in handleElement.value &&
                handleElement.value.pathFormula
            ) {
                const pathFormula =
                    SHAPE_PATH_FORMULAS[handleElement.value.pathFormula]

                let path = ''
                if ('editable' in pathFormula) {
                    path = pathFormula.formula(
                        width,
                        height,
                        handleElement.value.keypoint!
                    )
                } else {
                    path = pathFormula.formula(width, height)
                }

                return {
                    viewBox: [width, height],
                    path
                }
            }
            return null
        }

        // 设置元素宽度、高度、旋转角度
        const updateWidth = (value: number) => {
            let props = { width: value }
            const shapePathData = updateShapePathData(value, height.value)
            if (shapePathData) props = { ...props, ...shapePathData }
            store.commit(MutationTypes.UPDATE_ELEMENT, {
                id: handleElement.value.id,
                props
            })
            addHistorySnapshot()
        }
        const updateHeight = (value: number) => {
            const props = { height: value }
            store.commit(MutationTypes.UPDATE_ELEMENT, {
                id: handleElement.value.id,
                props
            })
            addHistorySnapshot()
        }
        const updateRotate = (value: number) => {
            const props = { rotate: value }
            store.commit(MutationTypes.UPDATE_ELEMENT, {
                id: handleElement.value.id,
                props
            })
            addHistorySnapshot()
        }

        // 固定元素的宽高比
        const updateFixedRatio = (value: boolean) => {
            const props = { fixedRatio: value }
            store.commit(MutationTypes.UPDATE_ELEMENT, {
                id: handleElement.value.id,
                props
            })
            addHistorySnapshot()
        }

        // 将元素旋转45度（顺时针或逆时针）
        const updateRotate45 = (command: '+' | '-') => {
            let _rotate = Math.floor(rotate.value / 45) * 45
            if (command === '+') _rotate = _rotate + 45
            else if (command === '-') _rotate = _rotate - 45

            if (_rotate < -180) _rotate = -180
            if (_rotate > 180) _rotate = 180

            const props = { rotate: _rotate }
            store.commit(MutationTypes.UPDATE_ELEMENT, {
                id: handleElement.value.id,
                props
            })
            addHistorySnapshot()
        }

        return {
            handleElement,
            orderElement,
            alignElementToCanvas,
            left,
            top,
            width,
            height,
            rotate,
            fixedRatio,
            minSize,
            updateLeft,
            updateTop,
            updateWidth,
            updateHeight,
            updateRotate,
            updateFixedRatio,
            updateRotate45
        }
    }
})
