
import { defineComponent } from 'vue'
import { SHAPE_LIST, ShapePoolItem } from '@/configs/shapes'
import { LINE_LIST, LinePoolItem } from '@/configs/lines'

import LinePointMarker from '@/views/components/element/LineElement/LinePointMarker.vue'

export default defineComponent({
  name: 'shape-pool',
  components: {
    LinePointMarker
  },
	setup(props, { emit }) {
		const shapeList: {
			type: string
			children: any[]
			elementType?: string
		}[] = [...LINE_LIST, ...SHAPE_LIST]

		const selectShape = (shape: ShapePoolItem) => {
			emit('select', shape, 'shape')
    }
    const selectLine = (line: LinePoolItem) => {
      emit('select', line, 'line')
    }

		return {
			shapeList,
      selectShape,
      selectLine
		}
	}
})
