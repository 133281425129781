
import { computed, defineComponent, PropType } from 'vue'
import { useStore } from '@/store'

import { PPTShapeElement } from '@/types/slides'
import { OperateResizeHandler } from '@/types/edit'
import useCommonOperate from '../hooks/useCommonOperate'

import RotateHandler from './RotateHandler.vue'
import ResizeHandler from './ResizeHandler.vue'
import BorderLine from './BorderLine.vue'
import { SHAPE_PATH_FORMULAS } from '@/configs/shapes'

export default defineComponent({
	name: 'shape-element-operate',
	inheritAttrs: false,
	components: {
		RotateHandler,
		ResizeHandler,
		BorderLine
	},
	props: {
		elementInfo: {
			type: Object as PropType<PPTShapeElement>,
			required: true
		},
		isActiveGroupElement: {
			type: Boolean,
			required: true
		},
		isMultiSelect: {
			type: Boolean,
			required: true
		},
		rotateElement: {
			type: Function as PropType<(element: PPTShapeElement) => void>,
			required: true
		},
		scaleElement: {
			type: Function as PropType<
				(
					e: MouseEvent,
					element: PPTShapeElement,
					command: OperateResizeHandler
				) => void
			>,
			required: true
		},
		moveShapeKeypoint: {
			type: Function as PropType<
				(e: MouseEvent, element: PPTShapeElement) => void
			>,
			required: true
		}
	},
	setup(props) {
		const store = useStore()
		const canvasScale = computed(() => store.state.canvasScale)

		const scaleWidth = computed(
			() => props.elementInfo.width * canvasScale.value
		)
		const scaleHeight = computed(
			() => props.elementInfo.height * canvasScale.value
		)
		const { resizeHandlers, borderLines } = useCommonOperate(
			scaleWidth,
			scaleHeight
		)
		const keypointStyle = computed(() => {
			if (!props.elementInfo.pathFormula || !props.elementInfo.keypoint) {
				return {}
			}

			const pathFormula = SHAPE_PATH_FORMULAS[props.elementInfo.pathFormula]
			if ('editable' in pathFormula) {
				const keypointPos =
					pathFormula.getBaseSize(
						props.elementInfo.width,
						props.elementInfo.height
					) * props.elementInfo.keypoint
				if (pathFormula.relative === 'left') {
					return { left: keypointPos * canvasScale.value + 'px' }
				}
				if (pathFormula.relative === 'right') {
					return {
						left:
							(props.elementInfo.width - keypointPos) * canvasScale.value + 'px'
					}
				}
				if (pathFormula.relative === 'center') {
					return {
						left:
							((props.elementInfo.width - keypointPos) / 2) *
								canvasScale.value +
							'px'
					}
				}
				if (pathFormula.relative === 'top') {
					return { top: keypointPos * canvasScale.value + 'px' }
				}
				if (pathFormula.relative === 'bottom') {
					return {
						top:
							(props.elementInfo.height - keypointPos) * canvasScale.value +
							'px'
					}
				}
			}
			return {}
		})
		return {
			scaleWidth,
			resizeHandlers,
			borderLines,
			keypointStyle
		}
	}
})
