
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { CloseOutlined } from '@ant-design/icons-vue'
import { MutationTypes, useStore } from '@/store'
import toolbarsTabs, { ToolBarTab, ShowTabTypes } from '@/configs/toolbar'
import { PPTElement } from '@/types/slides'
import { ToolbarState, ToolbarStates } from '@/types/toolbar'

import ElementStylePanel from './ElementStylePanel/index.vue'
import ElementPositionPanel from './ElementPositionPanel.vue'
import ElementAnimationPanel from './ElementAnimationPanel.vue'
import SlideDesignPanel from './SlideDesignPanel.vue'
import SlideAnimationPanel from './SlideAnimationPanel.vue'
import MultiPositionPanel from './MultiPositionPanel.vue'
import SymbolPanel from './SymbolPanel.vue'
import LessonPlanPanel from '@/views/components/dynamicTabs/LessonPlanPanel.vue'
import SpeakLessonPanel from '@/views/components/dynamicTabs/SpeakLessonPanel.vue'

import useDynamicTabs from '@/hooks/useDynamicTabs'

// 类型与组件名称映射
const compNameMap = {
    lessonPlan: 'lessonPlan-panel',
    speaklesson: 'speakLesson-panel'
}

const panelMap = {
    [ToolbarStates.EL_STYLE]: ElementStylePanel,
    [ToolbarStates.EL_POSITION]: ElementPositionPanel,
    [ToolbarStates.EL_ANIMATION]: ElementAnimationPanel,
    [ToolbarStates.SLIDE_DESIGN]: SlideDesignPanel,
    [ToolbarStates.SLIDE_ANIMATION]: SlideAnimationPanel,
    [ToolbarStates.MULTI_POSITION]: MultiPositionPanel,
    [ToolbarStates.SYMBOL]: SymbolPanel,
    [ToolbarStates.LESSON_PLAN]: LessonPlanPanel,
    [ToolbarStates.SPEAK_LESSON]: SpeakLessonPanel
}

export default defineComponent({
    name: 'toolbar',
    components: { CloseOutlined },
    props: {
        changeHiddenToolBar: {
            type: Function
        }
    },
    setup(props) {
        const { editorCurrentTabs } = useDynamicTabs()
        const store = useStore()
        const toolbarState = computed(() => store.state.toolbarState)
        const handleElement = computed<PPTElement>(
            () => store.getters.handleElement
        )
        const currentFirstTabId = ref() // 当前选中的一级功能
        const keepAliveTabs: string[] = [] // 保持状态的组件名称
        const allTabs = computed(() => {
            // 所有的标签数据
            const _tabs = [...toolbarsTabs, ...editorCurrentTabs.value]
            _tabs.forEach((tab: ToolBarTab) => {
                if (panelMap[tab.tabType]) {
                    tab.comp = panelMap[tab.tabType]
                }
                if (tab.keepAlive && compNameMap[tab.tabType]) {
                    keepAliveTabs.push(compNameMap[tab.tabType])
                }
            })
            return _tabs
        })

        // 根据元素类型改变标签名称
        const tabNameMap = (_tabName: string, type: string) => {
            if (_tabName === '样式') {
                const typeStyleMap = {
                    text: '文本',
                    shape: '形状',
                    image: '图片',
                    'elf-audio': '音频',
                    'elf-video': '视频',
                    line: '线条箭头',
                    table: '表格',
                    chart: '图表',
                    'elf-sing-play': '曲谱',
                    'elf-carouse-image': '轮播图',
                    'elf-questions-bank': '题目',
                    'elf-emsemble-practice': '钢琴合奏',
                    'elf-pk-questions': '答题PK',
                }
                if (typeStyleMap[type]) {
                    return typeStyleMap[type]
                }
            }
            return _tabName
        }

        const setToolbarState = (value: ToolbarState) => {
            store.commit(MutationTypes.SET_TOOLBAR_STATE, value)
        }

        const activeElementIdList = computed(
            () => store.state.activeElementIdList
        )

        // 二级功能
        const currentTabs = computed(() => {
            const staticTabs: any[] = []
            const childTabs =
                allTabs.value.filter(
                    (tab: ToolBarTab) =>
                        tab.parentId === currentFirstTabId.value
                ) || []
            if (!handleElement.value) {
                childTabs.forEach((tab: ToolBarTab) => {
                    if (
                        !tab?.showType ||
                        tab.showType?.includes(ShowTabTypes.NOTHING)
                    ) {
                        staticTabs.push({ label: tab.name, value: tab.tabType })
                    }
                })
                return staticTabs
            }

            let type: any = handleElement.value?.type
            if (handleElement.value?.type === 'elf') {
                type = handleElement.value?.subtype
            }

            if (activeElementIdList.value.length === 0) {
                // 没有点击
                childTabs.forEach((tab: ToolBarTab) => {
                    if (
                        !tab?.showType ||
                        tab.showType?.includes(ShowTabTypes.NOTHING)
                    ) {
                        staticTabs.push({ label: tab.name, value: tab.tabType })
                    }
                })
            } else if (activeElementIdList.value.length > 1) {
                // 多选
                childTabs.forEach((tab: ToolBarTab) => {
                    if (
                        !tab?.showType ||
                        tab.showType?.includes(ShowTabTypes.MULTI)
                    ) {
                        if (
                            !tab?.elementTypes ||
                            tab?.elementTypes?.includes(type)
                        ) {
                            staticTabs.push({
                                label: tabNameMap(tab.name, type),
                                value: tab.tabType
                            })
                        }
                    }
                })
            } else {
                // 单选
                childTabs.forEach((tab: ToolBarTab) => {
                    if (
                        !tab?.showType ||
                        tab.showType?.includes(ShowTabTypes.CLICK)
                    ) {
                        if (
                            !tab?.elementTypes ||
                            tab?.elementTypes?.includes(type)
                        ) {
                            staticTabs.push({
                                label: tabNameMap(tab.name, type),
                                value: tab.tabType
                            })
                        }
                    }
                })
            }
            return staticTabs
        })

        watch(
            currentTabs,
            () => {
                const currentTabsValue = currentTabs.value.map(
                    (tab) => tab.value
                )
                if (!currentTabsValue.includes(toolbarState.value)) {
                    currentTabsValue[0] && store.commit(
                        MutationTypes.SET_TOOLBAR_STATE,
                        currentTabsValue[0]
                    )
                }
            },
            { immediate: true }
        )

        const currentPanelComponent = computed(() => {
            return (
                firstLevelTabs.value.find(
                    (tab: ToolBarTab) => tab.id === currentFirstTabId.value
                )?.comp ||
                panelMap[toolbarState.value] ||
                null
            )
        })

        const firstLevelTabs = computed(() => {
            if (activeElementIdList.value.length === 1) {
                // 单选
                return allTabs.value.filter(
                    (tab: ToolBarTab) =>
                        tab.parentId === 0 &&
                        (!tab?.showType ||
                            tab?.showType?.includes(ShowTabTypes.CLICK))
                )
            } else if (activeElementIdList.value.length > 1) {
                // 多选
                return allTabs.value.filter(
                    (tab: ToolBarTab) =>
                        tab.parentId === 0 &&
                        (!tab?.showType ||
                            tab?.showType?.includes(ShowTabTypes.MULTI))
                )
            }
            // 没有点击
            return allTabs.value.filter((tab) => tab.parentId === 0)
        })

        const changeFirstTab = (tab: any) => {
            const { id: selectedId, tabType } = tab
            if (currentFirstTabId.value === selectedId) {
                changeSecondToolBarVisible()
            } else {
                changeSecondToolBarVisible(true)
                setToolbarState(tabType)
            }
            currentFirstTabId.value = selectedId
        }

        // 是否有二级功能
        const secondLevelTabsVisible = computed(() => {
            return !firstLevelTabs.value.find(
                (tab: ToolBarTab) => tab.id === currentFirstTabId.value
            )?.comp
        })

        const secondToolBarVisible = ref(true)
        const changeSecondToolBarVisible = (state?: boolean) => {
            if (state !== undefined) {
                secondToolBarVisible.value = state
                props.changeHiddenToolBar && props.changeHiddenToolBar(!state)
            } else {
                secondToolBarVisible.value = !secondToolBarVisible.value
                props.changeHiddenToolBar && props.changeHiddenToolBar()
            }
        }

        watch(
            () => firstLevelTabs.value,
            () => {
                const isExit = firstLevelTabs.value.find(
                    (tab: ToolBarTab) => tab.id === currentFirstTabId.value
                )
                if (!isExit && secondToolBarVisible.value) {
                    currentFirstTabId.value = firstLevelTabs.value[0].id
                }
            }
        )

        watch(
            () => secondToolBarVisible.value,
            () => {
                if (!secondToolBarVisible.value) {
                    currentFirstTabId.value = undefined
                }
            }
        )

        onMounted(() => {
            currentFirstTabId.value = firstLevelTabs.value.find(
                (tab: ToolBarTab) => tab.state
            )?.id
            if (!currentFirstTabId.value && firstLevelTabs.value[0]) {
                currentFirstTabId.value = firstLevelTabs.value[0].id
            }
        })
        return {
            firstLevelTabs,
            changeFirstTab,
            currentFirstTabId,
            keepAliveTabs,
            secondLevelTabsVisible,
            toolbarState,
            currentTabs,
            setToolbarState,
            currentPanelComponent,
            secondToolBarVisible,
            changeSecondToolBarVisible
        }
    }
})
